<template>
    <div class="fastFoodLayout">
        <touTop></touTop>
        <router-view></router-view>
    </div>
</template>
<script>
import touTop from '../touTop/touTop_fastFood.vue'
export default {
    name:'fastFoodLayout',
    components:{
        touTop
    }
}
</script>
<style lang="less" scoped>
.fastFoodLayout{

}
</style>