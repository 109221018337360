//sort排序
export function compare(property) {
    return function(a, b) {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
    };
}

// 格式化日期
export function dateFormat(fmt, date) {
    let mydate = new Date(date);
    let ret;
    const opt = {
        "Y+": mydate.getFullYear().toString(), // 年
        "m+": (mydate.getMonth() + 1).toString(), // 月
        "d+": mydate.getDate().toString(), // 日
        "H+": mydate.getHours().toString(), // 时
        "M+": mydate.getMinutes().toString(), // 分
        "S+": mydate.getSeconds().toString() // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    //console.log(fmt);
    return fmt;

}

//uncode 转汉字
export function reconvert(str) {
    str = str.replace(/(&#)(\d{1,6});/gi, function($0) {
        return String.fromCharCode(parseInt(escape($0).replace(/(%26%23)(\d{1,6})(%3B)/g, "$2")));
    });
    return str;
}

//查询字符串中某个字符出现的次数
export function getStrNum(val, sign) {
    let str = String(val)
    var index = str.indexOf(sign)
    var num = 0
    while (index !== -1) {
        num++;
        index = str.indexOf(sign, index + 1)
    }
    return num
}

//限制小数点后两位
export function getTofixed(val) {
    let str = String(val)
    let newStr = String(val).replace(/[^0-9,.]/ig, "")
    let strNum = getStrNum(newStr, '.')
    if (strNum > 1) {
        newStr = newStr.slice(0, newStr.length - 1)
    }
    if (str[0] == 0 && str[1] && str[1] != '.') {
        newStr = newStr.slice(1)
    }
    let arr = str.split('.')
    if (arr.length > 1 && arr[1] && arr[1].length > 2) {
        newStr = arr[0] + '.' + arr[1].slice(0, 2)
    }

    return newStr
}

//整数
export function getInteger(val) {
    let str = String(val)
    let newStr = String(val).replace(/[^0-9]/ig, "")
    if (str[0] == 0 && str[1]) {
        newStr = newStr.slice(1)
    }
    return newStr
}