<template>
<div class="lunbo">
  <div class="notice-card-wrapper">
    <div class="header">
      <div class="title">
        <!-- 系统公告 -->
        <div class="message">
          <div class="inner-container">
            <span v-html="notice"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
   </div>

</template>
<script>
export default {
data() {
return {
   
    notice:'系统公告，今天天气晴朗'  //轮播中的文字
    
   
}
},
created() {},
computed: {
    
},
methods: { 
},  
mounted(){
    
},
updated(){
    
}
};
</script>
<style lang="less" scoped>
    .lunbo{
        height: .3rem;
        font-size: .14rem;
        color:white;
        line-height: .3rem;
        width:80%;
        .notice-card-wrapper {
            .inner-container {
                margin-left: 100%; // 把文字弄出可见区域
                width: 200%;
                animation: myMove 30s linear infinite; // 重点，定义动画
                animation-fill-mode: forwards;
            }
                /*文字无缝滚动*/
            @keyframes myMove {
                0% {
                transform: translateX(0);
                }
                100% {
                transform: translateX(-80vw);
                }
            }
        }

    }


 

</style>
