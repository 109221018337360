import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from "js-cookie";
import chineseFood from './chineseFood'
import fastFood from './fastFood'
import store from '@/store'
import { deleteAWeekAgo } from '@/public/indexDB'

Vue.use(VueRouter)

let foodType = 'feast'
let FOOD_TYPE = localStorage.getItem('FOOD_TYPE')
if (FOOD_TYPE) {
    foodType = FOOD_TYPE
} else {
    let hrefURL = window.location.href
    if (hrefURL.indexOf('restaurant') > -1) {  // 轻餐
        foodType = 'restaurant'
    } else if (hrefURL.indexOf('shops') > -1) {  // 母婴/便利/生鲜
        foodType = 'shops'
    }else if (hrefURL.indexOf('Tea') > -1) { // 奶茶烘焙
        foodType = 'Tea'
    }else{ // 重餐
        foodType = 'feast'
    }
    localStorage.setItem('FOOD_TYPE', foodType)
}

let routes = foodType == 'feast' ? chineseFood : fastFood
let base = foodType == 'feast' ? '/clerk' : '/fast' 

const router = new VueRouter({
    mode: 'history',
    base,
    routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
    let VNO = '1.0.8' //版本号
    let token = Cookies.get("Access-Token")
    if (!token && to.name != 'Login') {
        let Ar = JSON.parse(localStorage.getItem('Ar'));
        let username = localStorage.getItem('username')
        let user = JSON.parse(localStorage.getItem('user'));
        let FOOD_TYPE = localStorage.getItem('FOOD_TYPE')
        let OEM = localStorage.getItem('OEM')
        let install=JSON.parse(localStorage.getItem('install'))
        let screen=JSON.parse(localStorage.getItem('screen'))
        let LSSP_printer_id=JSON.parse(localStorage.getItem('LSSP_printer_id'))
        let printReceipt = localStorage.getItem('printReceipt')
        localStorage.clear()
        localStorage.setItem('printReceipt', printReceipt)
        localStorage.setItem('LSSP_printer_id', LSSP_printer_id)
        localStorage.setItem('OEM', OEM)
        localStorage.setItem('FOOD_TYPE', FOOD_TYPE)
        localStorage.setItem('username', username)
        localStorage.setItem('Ar', JSON.stringify(Ar))
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('install', JSON.stringify(install))
        localStorage.setItem('screen', JSON.stringify(screen))
        deleteAWeekAgo()
        next({ path: '/' + '?VNO=' + VNO})
    } else if (token && to.name == 'Login') {
        localStorage.removeItem('topTabList')
        localStorage.removeItem('activeName')
        store.commit('updateActiveName', 'About')
        next('/about' + '?VNO=' + VNO)
    } else {
        if(to.fullPath.indexOf('VNO') > -1){
            next()
        }else if(to.fullPath.indexOf('?') > -1){
            next(to.fullPath + '&VNO=' + VNO) 
        }else{
            next(to.fullPath + '?VNO=' + VNO)
        }
    }

})

export default router