
import request from "@/request/request";


// 登录方法请求
export function login(data) {
  // const data = {
  //   username,
  //   password,
  //   code,
  //   uuid,
  // };
  return request({
    url: "/clerk/passport/login",
    method: "post",
    data: data,
  });
}

//获取门店详情
export function shop_detail() {
  return request({
    url: "/clerk/shop/detail",
    method: "post",
  });
}

// export function menDian(data) { //请求门店列表
//   return request({
//     url: "/clerk/passport/shop",
//     method: "post",
//     data: data,
//   });
// }

export function loginOut(data) { //点击门店列表成功
  return request({
    url: "/clerk/passport/logout",
    method: "post",
    data: data,
  });
}
export function shopStorey(data) { //获取餐厅区域列表
  return request({
    url:"/clerk/storey/list",
    method:"get",
    data  
  });
}
export function tableList(data) { //获取桌台列表
  return request({
    url:"/clerk/table/list",
    method:"get",
    params:data
  });
}
export function list (params) { //系统设置获取绑定打印机列表
  return request({
    url: "/clerk/printer/newlist",
    method: 'get',
    params
  })
}
export function getReceiptList (params) { //新增打印机单据列表
  return request({
    url: "/clerk/printer/getReceiptList",
    method: 'get',
    params
  })
}
export function   newadd (data) { //新增打印机单据列表
  if(data.form.status===true){
    data.form.status=0
  }else{
    data.form.status=1
  }
  if(!data.form.printer_config){
    data.form.printer_config={}
  }
  if(data.form.only_cut===true){
    data.form.only_cut=2
  }else{
    data.form.only_cut=1
  }
  if(data.form.is_order_printer===true){
    data.form.is_order_printer=2
  }else{
    data.form.is_order_printer=1
  }
  return request({
    url: "/store/setting.printer/newadd",
    method: 'post',
    data
  })
}
export function deleted (data) {  //删除打印机数据
  return request({
    url: '/clerk/printer/newdelete',
    method: 'post',
    data: data
  })
}
export function newedit (data) {  //编辑打印机数据
  if(data.form.status===true){
    data.form.status=0
  }else{
    data.form.status=1
  }
  if(data.form.only_cut===true){
    data.form.only_cut=2
  }else{
    data.form.only_cut=1
  }
  if(!data.form.printer_config){
    data.form.printer_config={}
  }
  if(data.form.printer_type==='本地打印机'){
    data.form.printer_type='0'
  }else if(data.form.printer_type==='网络打印机'){
    data.form.printer_type='1'
  }else if(data.form.printer_type==='云打印机'){
    data.form.printer_type='2'
  }
  if(data.form.is_order_printer===true){
    data.form.is_order_printer=2
  }else{
    data.form.is_order_printer=1
  }
  return request({
    url: '/clerk/printer/newedit',
    method: 'post',
    data: data
  })
}
export function  goodsListRequest (data) {    //获取所有商品分类
  return request({
    url: '/clerk/goods/clist',
    method: 'post',
    data: data
  })
}
export function PrinterGoods (data) {   //商品关联关系确定
  return request({
    url: '/clerk/printer/goods',
    method: 'post',
    data: data
  })
}

export function  getGoods (data) {   //获取打印机绑定的商品
  return request({
    url: '/clerk/printer/getGoods',
    method: 'post',
    data: data
  })
}

export function  checkPwd (data) {   //锁屏解锁验证
  return request({
    url: '/clerk/passport/checkPwd',
    method: 'post',
    data: data
  })
}
export function  toStore (data) {   //锁屏解锁验证
  return request({
    url: '/clerk/passport/tostore',
    method: 'post',
    data: data
  })
}


export function  getOem (data) {   //获取OEM 信息
  return request({
    url: '/clerk/oem/getOem',
    method: 'post',
    data: data
  })
}
