<template>
  <div class="login_top">
    <div class="tab">
      <div @click.stop="clickTab(item)" v-for="(item,i) in topTabList" :key="item.name"
      :class="[activeName === item.name ? 'active' : '', 'tablist']" v-show="item">
        <div class="tabTitle">{{item.title}}</div>
        <a-icon type="close-circle" @click.stop="deleteTab(item)" class="delete" v-if="item.isDetele"/>
        <div class="borderRight" v-if="activeIndex != i && activeIndex-1 != i"></div>
      </div>
    </div>
    <div class="right">
      <div class="name">{{ shop_name }}</div>
      <span class="iconfont icon-wifi" v-if="WS_OK"></span>
      <span class="iconfont icon-duanwang" v-else></span>
      <div class="bg">
        <div title="刷新" @click="againdata" class="shuaxin">
          <a-icon type="sync" />
        </div>
        <!-- <div class="shuaxin phone lian">
          <img src="@/assets/24gf-headset.png" alt="" class="img">
           <img src="@/assets/24gl-headset.png" alt="" class="activemig">
          <div class="phones">
            <span>
                <img src="@/assets/phone.png" alt="" class="imgphone">
            </span>
            <span>15901512299</span>
          </div>
        </div> -->
        <div title="最小化" class="shuaxin" @click="winMin">
          <a-icon type="minus" />
        </div>
        <div title="关闭系统" class="shuaxin phone close" @click="winClose">
          <a-icon type="poweroff" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState , mapActions } from "vuex";
// import Cookies from "js-cookie";
export default {
  props: ["headername"],
  data() {
    return {
      visible:false,
      activeIndex:0
    };
  },
  created() {},
  computed: {
    ...mapState(["shop_name","topTabList",'activeName','WS_OK']),
  },
  methods: {
    ...mapActions(['updateTabListAction','activeNameAction']),
    vuexHandle(data){
      this.activeNameAction(data.name)
      let arr = JSON.parse(JSON.stringify(this.topTabList))
      let i = arr.findIndex(item => item.name == data.name)
      if(i < 0){
        data.isDetele = true
        arr.push(data)
        this.updateTabListAction(arr)
      }
    },
    againdata() {
      this.$confirm({
        title: "提示",
        content: "确定要重新加载页面么?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          window.location.reload();
        },
        onCancel() {},
      });
    },
    clickTab(item) {
      this.activeNameAction(item.name)
      this.$router.push(item.path);
    },
    deleteTab(data){
      let arr = JSON.parse(JSON.stringify(this.topTabList))
      let i = arr.findIndex(item => item.name == data.name)
      if(data.name == this.activeName){
        if(i + 1 < arr.length){
          arr.splice(i,1)
          this.activeNameAction(arr[i].name)
          this.updateTabListAction(arr)
          this.$router.push(arr[i].path)
        }else if(i+1 == arr.length){
          arr.splice(i,1)
          this.activeNameAction(arr[i-1].name)
          this.updateTabListAction(arr)
          this.$router.push(arr[i-1].path)
        }
      }else{
        if(i > 0){
          arr.splice(i,1)
          this.updateTabListAction(arr)
        }
      }
    },
    //关闭窗口
    winClose(){
      console.log(this.$route.path);
      if(this.$route.path == '/'){
        this.$confirm({
          title: "提示",
          content: "确定要退出吗？",
          okText: "确定",
          cancelText: "取消",
          onOk() {
            bound.formClose();
          },
          onCancel() {},
        });
      }else{
        this.vuexHandle({
          title:'营业',
          path:'/yingye',
          name: 'Yingye'
        })
        this.$router.push({
          path:'/yingye',
          query:{
            jiaoban:'close'
          }
        })
        if(this.$route.path == '/yingye'){
          location.reload();
        }
      }
      
      
    },
    //最小化窗口
    winMin(){
      bound.minForm();
    },
  },
  mounted() {
    let activeName = localStorage.getItem('activeName')
    if(activeName){
      this.activeNameAction(activeName)
      this.activeIndex = this.topTabList.findIndex(item => item.name == activeName)
    }
  },
  updated() {},
};
</script>
<style lang="less" scoped>
.login_top {
  width: 100%;
  height: 4.6vh;
  display: flex;
  background: #222222;
  justify-content: space-between;
  .tab {
    display: flex;
    justify-content: center;
    font-size: 1.8vh;
    padding-top: 0.4vh;
    .tablist {
      width: 9.5vw;
      color: white;
      text-align: center;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      border-radius: 1.5vh 1.5vh 0 0;
      cursor: pointer;
      &:hover{
        background-color: #070707;
      }
      .tabTitle{
        flex:1;
        line-height: 1.8vh;
      }
      .delete{
        border-radius: 50%;
        margin:0 1.2vh 0 0.6vh;
        &:hover{
          background: #d44027;
        }
      }
      .borderRight{
        border: 1px solid #000000;
        height: 2vh;
      }
    }
    .active {
      background: #070707;
      color: #ff6700;
    }
    
  }
  .right {
    display: flex;
    align-items: center;
    .bg{
      display: flex;
      :hover {
        background-color: #273442 !important ;
        color: #d44027;
        cursor: pointer;
      }
    }
    .icon-wifi{
      color: #ff5500;
      padding-right: 1vh;
      font-size: 2vh;
    }
    .icon-duanwang{
      color: #d9d9d9;
      font-size: 2.6vh;
      padding-right: 1vh;
    }
    div {
      color: #ffffff;
      
    }
    .name {
      line-height: 4.6vh;
      text-align: right;
      padding: 0 1vw;
      color: #ff6700;
      font-size: 2vh;
      max-width: 11vw;
      overflow: hidden;
			text-overflow:ellipsis;
			white-space: nowrap;
      :hover {
        background-color: #222;
      }
    }
    .shuaxin {
      padding: 0 0.6vw;
      font-size: 1.7vw;
      height: 4.6vh;
      line-height: 4.6vh;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1px;
      border-left: #111 solid 2px;
    }
    .close {
      background-color: #d44027;
      font-size: 1.7vw;
      margin: 0;
      border: 0;
      padding: 0 0.8vw;
      
    }
    div:hover:nth-of-type(5) {
      cursor: pointer;
    }
    div:hover:nth-of-type(6) {
      cursor: pointer;
    }
    div:nth-of-type(5) {
      color: white;
    }
    div:nth-of-type(6) {
      color: white;
    }
  }
}
img{
  width:1.8vw;
  height: 1.8vw;
}
.phones{
  display: none;
  position: absolute;
  left:-2.3vw;
  top:6vh;
  width:14vh;
  background: #42505f;
  font-size: 1.6vh;
  border-radius: 4px;
  padding-left:0.3vw;
}
.lian{
  position: relative;
  &:hover{
     .activemig{
        display: block;
      }
      .img{
        display: none;
      }
    .phones{
      display: block;
    }
  }
}
.phones::before{
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: #42505f;
  top: -1.5vh;
  left: 3.2vw;
}
.activemig{
  display: none;
}
.imgphone{
  width:1vw;
  height: 1vw;
  margin-right: 0.2vw;
}
/deep/.ant-btn-primary{
  background-color: #ff6600;
  border-color:#ff6600 ;
}
/deep/.ant-btn-primary:hover{
  background-color: #ff6600;
  border-color:#ff6600 ;
}
/deep/.ant-modal-body{
  font-size: 1.6vh;
}
/deep/.ant-modal-header{
  font-size: 1.8vh;
}

</style>
