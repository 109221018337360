<template>
    <ul class="keyboard">
        <li v-for="(key,index) in keyList" 
        :key="index" track-by="$index" 
        :class="{delete: key === '删除', tab: key === 'Tab', capslock: key === '大写',capslocks: key === '小写', enter: key === '确定', shift: key === 'Shift', space: key === '空格', shifted: (key === 'Shift') && hasShifted, capsed: (key === '大写') && hasCapsed }" v-text="key"
         @click="clickKey(key)">
         </li>
    </ul>
</template>
<script>
    export default {
        data() {
            return {
                keyList: [],
                normalKeyList: [],
                shiftedKeyList: [],
                capsedKeyList: [],
                hasShifted: false,
                hasCapsed: false,
                keyvalue:'',
                userkeyvalue:'',
                passkeyvalue:'',
                shop_numbervalue:'',
            }
    },
    created(){
      this.ready();
    },
    props:['keyboards','form','onSubmit'],
    methods: {
            clickKey(key) {
                // console.log(this.form)
                this.userkeyvalue=this.form.username;
                if(this.keyboards==="username"){
                      switch(key) {
                    case "删除":
                        let kbt =this.userkeyvalue;
                        this.userkeyvalue = kbt.length ? kbt.substring(0, kbt.length - 1) : kbt;
                        break;

                    // case "Tab":
                    //     this.keyvalue += "\t";
                    //     break;

                    case "确定":
                        this.passkeyvalue += "";
                        break;

                    case "空格":
                        this.userkeyvalue += " ";
                        break;

                    case "大写":
                        this.hasCapsed = !this.hasCapsed;
                        this.keyList = this.hasCapsed ? this.capsedKeyList : this.normalKeyList; 
                        break;
                    case "小写":
                        this.hasCapsed = !this.hasCapsed;
                        this.keyList = this.hasCapsed ? this.capsedKeyList : this.normalKeyList; 
                        break;     

                    // case "Shift":
                    //     this.hasShifted = !this.hasShifted;
                    //     this.keyList = this.hasShifted ? this.shiftedKeyList : this.normalKeyList;
                    //     break;
          
                    default:
                        this.userkeyvalue += key;
                        break;
                }
               
                //console.log(this.keyvalue)
                this.$emit("updatekey",this.userkeyvalue)
                }else if(this.keyboards==="password"){
                    this.passkeyvalue=this.form.password;
                           switch(key) {
                    case "删除":
                        let kbt = this.passkeyvalue;
                        this.passkeyvalue = kbt.length ? kbt.substring(0, kbt.length - 1) : kbt;
                        break;

                    // case "Tab":
                    //     this.keyvalue += "\t";
                    //     break;

                    case "确定":
                        this.passkeyvalue += "";
                        break;

                    case "空格":
                        this.passkeyvalue += " ";
                        break;

                    case "大写":
                        this.hasCapsed = !this.hasCapsed;
                        this.keyList = this.hasCapsed ? this.capsedKeyList : this.normalKeyList; 
                        break;
                    case "小写":
                        this.hasCapsed = !this.hasCapsed;
                        this.keyList = this.hasCapsed ? this.capsedKeyList : this.normalKeyList; 
                        break; 
                    // case "Shift":
                    //     this.hasShifted = !this.hasShifted;
                    //     this.keyList = this.hasShifted ? this.shiftedKeyList : this.normalKeyList;
                    //     break;         
                    default:
                        this.passkeyvalue += key;
                        break;
                }
                //console.log(this.keyvalue)
                this.$emit("updatekey",this.passkeyvalue)

                }else if(this.keyboards==="shop_number"){
                     this.shop_numbervalue=this.form.shop_number;
                           switch(key) {
                    case "删除":
                        let kbt = this.shop_numbervalue;
                        this.shop_numbervalue = kbt.length ? kbt.substring(0, kbt.length - 1) : kbt;
                        break;

                    // case "Tab":
                    //     this.keyvalue += "\t";
                    //     break;

                    case "确定":
                        this.shop_numbervalue += "";
                        break;

                    case "空格":
                        this.shop_numbervalue += " ";
                        break;

                    case "大写":
                        this.hasCapsed = !this.hasCapsed;
                        this.keyList = this.hasCapsed ? this.capsedKeyList : this.normalKeyList; 
                        break;
                    case "小写":
                        this.hasCapsed = !this.hasCapsed;
                        this.keyList = this.hasCapsed ? this.capsedKeyList : this.normalKeyList; 
                        break;    

                    // case "Shift":
                    //     this.hasShifted = !this.hasShifted;
                    //     this.keyList = this.hasShifted ? this.shiftedKeyList : this.normalKeyList;
                    //     break;         
                    default:
                        this.shop_numbervalue += key;
                        break;
                }
                //console.log(this.keyvalue)
                this.$emit("updatekey",this.shop_numbervalue)

                }
                if(key==='确定'){ 
                                   
                    this.$parent.onSubmit('ruleForm')
                }
              
      },
      ready() {
            let normalKeyList = [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-',  '删除',
                         'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', '\\',
                        '大写', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', ".", '确定',
                         'z', 'x', 'c', 'v', 'b', 'n', 'm', 
                        '空格'], 

                shiftedKeyList = [ '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_',  '删除',
                        'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '{', '}', '|',
                        '大写', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ':', '.', '确定',
                        'Z', 'X', 'C', 'V', 'B', 'N', 'M', 
                        '空格'],

                capsedKeyList = [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-',  '删除',
                         'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '[', ']', '\\',
                        '小写', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ';', ".", '确定',
                        'Z', 'X', 'C', 'V', 'B', 'N', 'M', 
                        '空格'];

            this.keyList = this.normalKeyList = normalKeyList;
            // this.shiftedKeyList = shiftedKeyList;
            this.capsedKeyList = capsedKeyList;
        },
        showModal(){}
        },
        mounted(){
          
        }
    
    }
</script>
<style lang="less" scoped>
    .keyboard {
    width: 8.5rem;
    margin: 0;
    padding: 0;
    list-style: none;
    user-select: none;
    // display: flex;
    margin:0 auto;
    background-color: red !important;

    li {
        float: left;
        margin: 0 .05rem .05rem 0;
        width: .6rem;
        height: .6rem;
        line-height: .6rem;
        text-align: center;
        background: rgba(66, 80, 95, 1);
        // border: 1px solid #e5e5e5;
        border-radius: 5px;
        color:white;
        font-size: .3rem;
        background: #42505f;

        &:hover {
            position: relative;
            border-color: gray;
            cursor: pointer;
            // background: chartreuse;
        }
        &:active {
            top: 1px;
            left: 1px;
        }
    }

    .tab, .delete {
        width: 1.25rem;
    }
    .capslock {
        width:1.25rem;
    }
    .enter {
        width: 1.25rem;
    }
  
    .space {
        // clear: left;
        width: 2.55rem;
    }
    // .shifted {
    //     position: relative;
    //     top: 1px;
    //     left: 1px;
    //     border-color: #e5e5e5;
    //     cursor: pointer;
    // }
    .capsed {
        position: relative;
        top: 1px;
        left: 1px;
        border-color: #e5e5e5;
        cursor: pointer;
    }
    .capslocks{
         width:1.25rem;
    }

}    
</style>