<template>
    <div class="bottoms">
        <div class="first">
            <p>{{oemData.company_name}}</p>
            <p>咨询电话：{{oemData.contact_mobile}}</p>
            <p v-if="oemData.website_url">
                <span>网址：</span>
                <span @click="goto">{{oemData.website_url}}</span>
            </p>
            <p class="second" v-if="oemData.record">
                <a href="http://beian.miit.gov.cn/"  target="_blank">{{oemData.record}}</a>
            </p>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(['oemData']),
    },
    methods: { 
        goto(){
            window.open(this.oemData.website_url)
            // var text = 'https://www.qinghankeji.com'
            // var input = document.getElementById("input");
            // input.value = text;
            // input.select(); 
            // document.execCommand("Copy","false",null)
        }
    }
};
</script>
<style lang="less" scoped>
.bottoms {
    position: fixed;
    bottom:0;
    left: 0;
    width: 100%;
    height: 7vh!important;
    border-top: 1px solid #3a4958;
    .first{
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 7vh;
        p {
            color: #7998ab;
            margin: 0;
            margin-right: 30px;
            font-size: 1.8vh;
            span:nth-of-type(2) {
                // color: white;
                cursor: pointer;
            }
         }
    }
    .second{
        // font-size: 1.8vh;
        // text-align: center;
        // line-height: 3vh;
        // color: #7998ab;
        cursor: pointer;
        a{
          color: #dbdbdb; 
        }

    }
  }
 

</style>
