//开台详情页接口请求方法
import request from "@/request/request";


export function categoryList(data) {     //商品分类(点击tab)
  return request({
    url: "/clerk/category/list",
    method: "post",
    data: data,
  });
}
export function goodsList(data) {     //商品分类后的列表
  return request({
    url: "/clerk/goods/list",
    method: "post",
    data: data,
  });
}
export function goodsLists(data) {     //商品分类后的列表
  return request({
    url: "/store/goods/list",
    method: "post",
    data: data,
  });
}
export function orderDetail(params) {     //商品分类后的列表
  return request({
    url: "/clerk/order/detail",
    method: "get",
    params
  });
}

export function checkFoundingLogStatus(data) {     //校验开台状态
  return request({
    url: "/clerk/table/checkFoundingLogStatus",
    method: "post",
    data
  });
}

export function orderCreate(data) {     //送单
  return request({
    url: "/clerk/order/create",
    method: "post",
    data
  });
}
export function units(data) {     //临时菜品  单位
  return request({
    url: "/clerk/goods/units",
    method: "post",
    data
  });
}
export function create(data) {     //添加临时品项
  return request({
    url: "/clerk/goods/create",
    method: "post",
    data
  });
}
export function orderpay(data) {     //发起订单支付
  return request({
    url: "/clerk/order/orderpay",
    method: "post",
    data
  });
}
export function payType(params) {     //支付方式列表
  return request({
    url: "/clerk/payType/list",
    method: "get",
    params
  });
}
export function orderpaystatus(data) {     //获取支付状态
  return request({
    url: "/clerk/order/orderpaystatus",
    method: "post",
    data
  });
}
export function orderUser(data) {     //查询会员
  return request({
    url: "/clerk/order/user",
    method: "post",
    data
  });
}
export function orderDiscount(data) {     //设置会员折扣
  return request({
    url: "/clerk/order/discount",
    method: "post",
    data
  });
}
export function orderFree(data) {     //设置会员折扣
  return request({
    url: "/clerk/order/free",
    method: "post",
    data
  });
}
export function orderHang(data) {     //设置挂账
  return request({
    url: "/clerk/order/hang",
    method: "post",
    data
  });
}
export function finish(data) {     //设置挂账
  return request({
    url: "/clerk/order/finish",
    method: "post",
    data
  });
}
export function deleteGoods(data) {     //删除商品
  return request({
    url: "/store/goods/delete",
    method: "post",
    data
  });
}
export function editGoods(data) {     //编辑商品
  return request({
    url: "/store/goods/edit",
    method: "post",
    data
  });
}

//获取常用备注
export function queryRemarkList () {
  return request({
    url: '/store/setting/getRemark',
    method: 'post',
  })
}

//获取存放位置列表
export function getAccessPositionList (data) {
  return request({
    url: '/clerk/wine/getAccessPositionList',
    method: 'post',
    data
  })
}
//创建存放位置
export function createAccessPosition (data) {
  return request({
    url: '/clerk/wine/createAccessPosition',
    method: 'post',
    data
  })
}
//新增商品寄存
export function createWineAccess (data) {
  return request({
    url: '/clerk/wine/createWineAccess',
    method: 'post',
    data
  })
}
//根据手机号搜索存储人
export function searchWineUser (data) {
  return request({
    url: 'clerk/wine/searchWineUser',
    method: 'post',
    data
  })
}
//获取寄存列表
export function getWineUserList (data) {
  return request({
    url: '/clerk/wine/getWineUserList',
    method: 'post',
    data
  })
}
//保存商品模式
export function screenSetting (data) {
  return request({
    url: '/clerk/shop/screenSetting',
    method: 'post',
    data
  })
}
//保存商品模式
export function getScreenSetting (data) {
  return request({
    url: '/clerk/shop/getScreenSetting',
    method: 'post',
    data
  })
}
//获取某个人的寄存信息
export function getAccessList (data) {
  return request({
    url: '/clerk/wine/getAccessList',
    method: 'post',
    data
  })
}
//获取某个人的寄存信息
export function getMobileSms (data) {
  return request({
    url: '/clerk/wine/getMobileSms',
    method: 'post',
    data
  })
}
//获取某个人的寄存信息
export function takeoutWineAccess (data) {
  return request({
    url: '/clerk/wine/takeoutWineAccess',
    method: 'post',
    data
  })
}
//获取某个人的寄存信息
export function positionDefault (data) {
  return request({
    url: '/clerk/wine/updateAccessPositionDefault',
    method: 'post',
    data
  })
}
//快速收款
export function fastPayOrder (data) {
  return request({
    url: '/clerk/order/fastPayOrder',
    method: 'post',
    data
  })
}





