<template>
  <div class="touTop_common_top">
    <div class="tab">
      <div @click.stop="clickTab(item)" v-for="(item,i) in topTabList" :key="item.name">
        <div v-if="item.name === 'About'" class="logo" @click="activeIndex = i">
          <img :src="oemData.upper_left_img" alt="">
          <div class="borderRight" v-if="activeIndex != i && activeIndex-1 != i"></div>
        </div>
        <div v-else :class="[activeName === item.name ? 'active' : '', 'tablist']" @click="activeIndex = i">
          <div class="tabTitle">{{item.title}}</div>
          <a-icon type="close" @click.stop="deleteTab(item)" class="delete" v-if="item.isDetele"/>
          <div class="borderRight" v-if="activeIndex != i && activeIndex-1 != i"></div>
        </div>
      </div>
    </div>
    <div class="right">
      <!-- <div class="name">{{ shop_name }}</div>
      <span class="iconfont icon-wifi" v-if="WS_OK"></span>
      <span class="iconfont icon-duanwang" v-else></span> -->
      <div class="bg">
        <div title="锁屏" class="shuaxin" @click="suoping">
          <span class="iconfont icon-suo"></span>
        </div>
        <div title="网络" class="wangluo" v-if="showShuaxin">
          <span class="iconfont icon-wifi" v-if="WS_OK"></span>
          <span class="iconfont icon-duanwang" v-else></span>
        </div>
        <div title="刷新" @click="againdata" class="shuaxin" v-if="showShuaxin">
          <span class="iconfont icon-shuaxin textColor"></span>
        </div>
        <div title="最小化" class="shuaxin" @click="winMin">
          <a-icon type="minus" />
        </div>
        <div title="关闭系统" class="shuaxin" @click="winClose">
          <a-icon type="poweroff" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState , mapActions , mapMutations } from "vuex";
import { getScreenSetting } from '@/request/business.js'
import Cookies from "js-cookie";
// import Cookies from "js-cookie";
export default {
  props: ["headername"],
  data() {
    return {
      visible:false,
      activeIndex:0,
      showShuaxin:true
    };
  },
  created() {},
  computed: {
    ...mapState(["shop_name","topTabList",'activeName','WS_OK' , 'oemData']),
  },
  methods: {
    ...mapActions(['updateTabListAction','activeNameAction']),
    ...mapMutations(['updateCashier']),
    vuexHandle(data){
      this.activeNameAction(data.name)
      let arr = JSON.parse(JSON.stringify(this.topTabList))
      let i = arr.findIndex(item => item.name == data.name)
      if(i < 0){
        data.isDetele = true
        arr.push(data)
        this.updateTabListAction(arr)
      }
    },
    againdata() {
      this.$confirm({
        title: "提示",
        content: "确定要重新加载页面么?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          window.location.reload();
        },
        onCancel() {},
      });
    },
    clickTab(item) {
      this.activeNameAction(item.name)
      this.$router.push(item.path);
    },
    deleteTab(data){
      let arr = JSON.parse(JSON.stringify(this.topTabList))
      let i = arr.findIndex(item => item.name == data.name)
      if(data.name == this.activeName){
        if(i + 1 < arr.length){
          arr.splice(i,1)
          this.activeNameAction(arr[i].name)
          this.updateTabListAction(arr)
          this.$router.push(arr[i].path)
        }else if(i+1 == arr.length){
          arr.splice(i,1)
          this.activeNameAction(arr[i-1].name)
          this.updateTabListAction(arr)
          this.$router.push(arr[i-1].path)
        }
      }else{
        if(i > 0){
          arr.splice(i,1)
          this.updateTabListAction(arr)
        }
      }
    },
    //关闭窗口
    winClose(){
      console.log(this.$route.path);
      if(this.$route.path == '/'){
        this.$confirm({
          title: "提示",
          content: "确定要退出吗？",
          okText: "确定",
          cancelText: "取消",
          onOk() {
            bound.formClose();
          },
          onCancel() {},
        });
      }else{
        this.vuexHandle({
          title:'交班',
          path:'/jiaoban',
          name: 'jiaoban'
        })
        
        if(this.$route.path == '/jiaoban'){
          location.reload();
        }else{
          this.$router.push({
            path:'/jiaoban',
            query:{
              jiaoban:'close'
            }
          })
        }
      }
      
      
    },
    //最小化窗口
    winMin(){
      bound.minForm();
    },
    suoping(){
      this.$router.push("/Password");
    },
    getCashier(){
      if (Cookies.get('Access-Token') && Cookies.get('Access-Token') != ''){
        getScreenSetting({key:'goods_model'}).then(res => {
            if(res.data.status === 200){
                const values=res.data.data.values
                this.updateCashier(values.cashier_mode_type || 1)
            }
        })
      }
    },
  },
  watch:{
    activeName(val){
      this.activeIndex = this.topTabList.findIndex(item => item.name == val)
    },
    $route(val){
      if(val.name == 'About' || val.name == 'Login' || val.name == 'quickCashier'){
        this.showShuaxin = true
      }else{
        this.showShuaxin = false
      }
    },
  },
  mounted() {
    this.getCashier()
    let activeName = localStorage.getItem('activeName')
    if(activeName){
      this.activeNameAction(activeName)
      this.activeIndex = this.topTabList.findIndex(item => item.name == activeName)
    }
    let routeName = this.$route.name
    if(routeName == 'About' || routeName == 'Login' || routeName == 'quickCashier'){
        this.showShuaxin = true
      }else{
        this.showShuaxin = false
      }
  },
  updated() {},
};
</script>
<style lang="less" scoped>



/deep/.ant-modal-body{
  font-size: 1.6vh;
}
/deep/.ant-modal-header{
  font-size: 1.8vh;
}
::v-deep .ant-modal-confirm-btns .ant-btn-primary{
  background: #000!important;
}
</style>
