<template>
  <div id="app">
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view >
    </keep-alive>  -->
    <!-- <router-view v-if="!$route.meta.keepAlive">
    </router-view> -->
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState , mapActions , mapMutations} from "vuex";
import { getOem } from './request/api.js'
export default {
  name: "App",
  components: {},
  data() {
    return {
      WStimer:null,
      oldTime:1
    };
  },
  computed: {
    ...mapState(["websockTime","websockKey","websockData","shop_info"]),
  },
  methods: {
    ...mapActions(['updateTabListAction','activeNameAction','updateShopInfoAction']),
    ...mapMutations(["updateWSKey","set_cartList","set_pendingOrder" , "set_currentMember" , "set_orderInfo" ,
     "set_returnData" , "set_returnGoods" , "set_returnMember" , "updateOemData" , "updatePermission"]),
    setHtmlFontSize() {
      const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
      //console.log(htmlWidth)
      const htmlDom = document.getElementsByTagName("html")[0];
      htmlDom.style.fontSize = (htmlWidth / 1400) * 100 + "px";
    },
    
    // 10秒发一次消息看ws是否断开
    websockDetect(){
        let shop = JSON.parse(localStorage.getItem('shop_info'))
        this.socketApi.websocketsend({
          key:'heartBeat',
          value:{
            type:'KHD',
            shop_id: shop && shop.shop_id ? shop.shop_id : '',
            shop_name: shop && shop.shop_name ? shop.shop_name : '',
          }
          
        })
        if(this.websockTime  === this.oldTime){
            this.socketApi.initWebSocket()
            this.oldTime += 1
        }else{
            this.oldTime = this.websockTime
        }
    },
  },
  watch:{
      websockKey(){
        if(this.websockKey === 'printBind'){
          this.updateWSKey('clear')
          this.$message.error(this.websockData.value.message);
        }
      },
  },
  mounted() {
    // 获取OEM信息
    // 河南良领智能科技有限公司：iA4bG2dU4eC0qD5a
    // 青翰信息技术有限公司：eA2mI2lD0fE1eC2c
    let OEM = 'eA2mI2lD0fE1eC2c'
    if(localStorage.getItem('OEM') && localStorage.getItem('OEM') != 'null'){
      OEM = localStorage.getItem('OEM')
    }else{
      let hrefURL = window.location.href
      // console.log('hrefURL===',hrefURL);
      if (hrefURL.indexOf('iA4bG2dU4eC0qD5a') > -1) { // 良领智能
          OEM = 'iA4bG2dU4eC0qD5a'
      } else { // 重餐
          OEM = 'eA2mI2lD0fE1eC2c'
      }
      localStorage.setItem('OEM', OEM)
    }
    getOem({oem_idstring : OEM}).then(res => {
      if(res.data.status == 200){
        this.updateOemData(res.data.data)
      }
    })
    // let OEMDATA = localStorage.getItem('OEMDATA')
    // if(OEMDATA && OEMDATA.oem_id){
    //   alert(2)
    //   this.updateOemData(JSON.parse(OEMDATA))
    // }

    this.setHtmlFontSize();
    this.socketApi.sendSock()
    let topTabList = localStorage.getItem('topTabList')
    if(topTabList){
      this.updateTabListAction(JSON.parse(topTabList))
    }
    
    let activeName = localStorage.getItem('activeName')
    if(activeName){
      this.activeNameAction(activeName)
    }

    //快餐
    let cartList = localStorage.getItem('cartList')
    if(cartList){
      this.set_cartList(JSON.parse(cartList))
    }
    let pendingOrder = localStorage.getItem('pendingOrder')
    if(pendingOrder){
      this.set_pendingOrder(JSON.parse(pendingOrder))
    }
    let currentMember = localStorage.getItem('currentMember')
    if(currentMember){
      this.set_currentMember(JSON.parse(currentMember))
    }
    let orderInfo = localStorage.getItem('orderInfo')
    if(orderInfo){
      this.set_orderInfo(JSON.parse(orderInfo))
    }
    let returnData = localStorage.getItem('returnData')
    if(returnData){
      this.set_returnData(JSON.parse(returnData))
    }
    let returnGoods = localStorage.getItem('returnGoods')
    if(returnGoods){
      this.set_returnGoods(JSON.parse(returnGoods))
    }
    let returnMember = localStorage.getItem('returnMember')
    if(returnMember){
      this.set_returnMember(JSON.parse(returnMember))
    }
    //快餐 

    //权限数组
    let permission = localStorage.getItem('permission')
    if(permission){
      this.updatePermission(JSON.parse(permission))
    }
    
    let shop_info = localStorage.getItem('shop_info')
    if(shop_info){
      this.updateShopInfoAction(JSON.parse(shop_info))
    }

    this.WStimer = setInterval(this.websockDetect, 10000);
  },
  beforeDestroy() {    //页面关闭时清除定时器  
    clearInterval(this.WStimer);
  },
  destroyed() {
    // this.websock.close() //离开路由之后断开websocket连接
  },
};
</script>

<style lang="less">
body{
  // font-family:Microsoft YaHei;
  // font-family: 'Microsoft YaHei' !important;
   font-family: 'PF' !important;
}
  ul{
    list-style:none;
    padding:0;
    margin:0 ;
  }
body {
  overflow-x:hidden;
  overflow-y:hidden;
}
.ant-modal-close-x .anticon svg{
  font-size: 3.5vh !important;
  margin-top:1.5vh;
  color: #999999;
}
.ant-form-item label{
  font-size: 1.8vh !important;
}
.ant-input{
  font-size: 1.8vh !important;
}
.ant-modal-title{
  font-size: 2vh !important;
  font-weight: bold !important;

}

</style>
