import Login from '@/views/Login.vue'

const chineseFood = [{
        path: '/',
        name: 'Login',
        component: Login
    },

    // {
    //   path:'/',
    //   name:'Me',
    //   component:()=>import('@/views/stock/demo.vue')
    // },
    // {
    //   path:'/lock',
    //   name:'Lock',
    //   component:()=>import('@/views/lock/Locklogin.vue')
    // },
    {
        path: '/password',
        name: 'Password',
        component: () =>
            import ('@/views/lock/Password.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('@/views/About.vue')
    },
    {
        path: '/yingye',
        name: 'Yingye',
        component: () =>
            import ('@/views/yingye/index.vue')
    },
    {
        path: '/order',
        name: 'Order',
        component: () =>
            import ('@/views/order/index.vue')
    },
    {
        path: '/button',
        name: 'Button',
        component: () =>
            import ('@/views/button/index.vue')
    },
    {
        path: '/install',
        name: 'install',
        component: resolve => require(['@/views/shezhi/shezhi.vue'], resolve),
        children: [{
                path: 'index',
                component: resolve => require(['@/views/shezhi/modules/index.vue'], resolve),
            },
            {
              path: 'weight',
              component: resolve => require(['@/views/shezhi/modules/weight.vue'], resolve),
            }, 
        ]

    },
    {
        path: '/bill',
        name: 'bill',
        component: resolve => require(['@/views/bill/bill.vue'], resolve),
        children: [{
                path: 'flow',
                component: resolve => require(['@/views/bill/modules/flow.vue'], resolve),
            },
            {
              path: 'detail',
              component: resolve => require(['@/views/bill/modules/detail.vue'], resolve),
            },
            {
                path: 'return',
                component: resolve => require(['@/views/bill/modules/return.vue'], resolve),
            },
        ]
    },

    {
        path: '/mis',
        name: 'mis',
        component: resolve => require(['@/views/mis/Member.vue'], resolve),
        children: [{
                path: 'system',
                component: resolve => require(['@/views/mis/modules/System.vue'], resolve),
            },
            {
                path: 'turnover',
                component: resolve => require(['@/views/mis/modules/turnover.vue'], resolve),
            },
            {
                path: 'record',
                component: resolve => require(['@/views/mis/modules/record.vue'], resolve),
            },
        ]


    },

    {
        path: '/stock',
        name: 'stock',
        component: resolve => require(['@/views/stock/frmLoss.vue'], resolve),
        children: [{
                path: 'damaged',
                component: resolve => require(['@/views/stock/modules/damaged.vue'], resolve),
            },
            {
                path: 'check',
                component: resolve => require(['@/views/stock/modules/check.vue'], resolve),
            },
            {
                path: 'sellOut',
                component: resolve => require(['@/views/stock/modules/sellOut.vue'], resolve),
            },
            {
                path: 'goodsList',
                component: resolve => require(['@/views/stock/modules/goodsList.vue'], resolve),
            },
            {
                path: 'peopleGoods',
                component: resolve => require(['@/views/stock/modules/peopleGoods.vue'], resolve),
            },
        ]
    },

    {
        path: '/reserve',
        name: 'reserve',
        component: resolve => require(['@/views/reserve/reserve.vue'], resolve),
        children: [{
                path: 'record',
                component: resolve => require(['@/views/reserve/modules/record.vue'], resolve),
            },
            {
                path: 'info',
                component: resolve => require(['@/views/reserve/modules/reserveInfo.vue'], resolve),
            },
            {
                path: 'list',
                component: resolve => require(['@/views/reserve/modules/tablelist.vue'], resolve),
            },
        ]
    },

    {
        path: '/openTable',
        name: 'openTable',
        component: resolve => require(['@/views/openTable/openTable.vue'], resolve)
    },
    {
        path: '/reportForm',
        name: 'ReportForm',
        component: resolve => require(['@/views/reportForm/reportForm.vue'], resolve),
        children: [{
                path: 'statistics',
                name: 'statistics',
                component: resolve => require(['@/views/reportForm/modules/statistics.vue'], resolve),
            },
            {
                path: 'orderCount',
                name: 'orderCount',
                component: resolve => require(['@/views/reportForm/modules/orderCount.vue'], resolve),
            },

        ]
    },
    {
        path: '/404',
        component: resolve => require(['../viewsFastFood/exception/404.vue'], resolve),
    },

    {
        path: '*',
        redirect: '/404',
        hidden: true
    }
]

export default chineseFood