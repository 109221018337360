<template>
<div class="login">
    <touTop />
    <div class="verstion">版本号：{{verstion}}</div>
    <div class="myLogin">
        <div class="img">
            <img :src="oemData.light_img_url" alt class="login_bos" />
            <img src="../assets/login_2.png" alt class="login_bos" />
        </div>
        <div class="login_box bigForm">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item ref="shop_number" prop="shop_number">
                    <a-auto-complete   v-model="form.shop_number" placeholder="请输入门店号" @focus="focusNumber()" :data-source="dataSource"  @change="onChange"/>
                </a-form-model-item>
                <a-form-model-item ref="username" prop="username">
                    <a-auto-complete v-model="form.username" placeholder="请输入账号/手机号" @focus="focusUsername()" :data-source="userArr"  @change="onChanges"/>
                </a-form-model-item>
                <a-form-model-item ref="password" prop="password">
                    <a-input type="password" v-model="form.password"  placeholder="请输入密码" @focus="focusPassword()" />
                </a-form-model-item>
                <a-checkbox class="checkbox" v-model="form.checked" ref="checked" prop="checked">记住我</a-checkbox>
                <a-form-model-item>
                    <a-button class="OkBtn" @click="onSubmit('ruleForm')">登录</a-button>
                </a-form-model-item>
            </a-form-model>
        </div>
    </div>
    
    <div class="code_box">
        <fastKeycode v-model="formVal" @change="codeChange" @okHandle="onSubmit('ruleForm')"></fastKeycode>
    </div>
    <div class="bottom">
        <span>{{oemData.company_name}}</span>
        <span class="beian" @click="goPage"  v-if="oemData.record">{{oemData.record}}</span>
        <span>终端号：{{form.terminal_no}}</span>
    </div>
</div>
</template>
<script>
import { login ,  shop_detail} from "../request/api";
import fastKeycode from "../components/keycode/fastKeycode.vue";
import Cookies from "js-cookie";
import touTop from "../components/touTop/touTop_fastFood.vue";
import {Base64} from 'js-base64';
import { mapState , mapActions , mapMutations } from "vuex";
import { getScreenSetting } from '@/request/business.js'
export default {
    name: "Home",
    data() {
        return {
            verstion:'',
            formVal:'',
            labelCol: { span: 0 },
            wrapperCol: { span: 24 },
            form: {
                username: Cookies.get("username") ? Cookies.get("username") : "",//用户名
                // password: Cookies.get("password")? Base64.decode(Cookies.get("password")) : "", //用户名密码form存储
                shop_number:Cookies.get("shop_number") ? Cookies.get("shop_number") : "", //门店号
                password: "", //用户名密码form存储
                checked:true,
                terminal_no:''
            },
            rules: {
                username: [{
                    required: true,
                    message: "请输入账号",
                    trigger: "change",
                }, ],
                password: [{
                    required: true,
                    message: "请输入密码",
                    trigger: "change",
                }],
                shop_number: [{
                    required: true,
                    message: "请输入门店号",
                    trigger: "change",
                }],
            },
            keyboards: "",
            dataSource:JSON.parse(localStorage.getItem('Ar'))?JSON.parse(localStorage.getItem('Ar')):[],   //门店号储存
            userArr:JSON.parse(localStorage.getItem('user'))?JSON.parse(localStorage.getItem('user')):[],   //用户名储存
        };
    },
    components: {
        fastKeycode,
        touTop,
    },
    computed: {
        ...mapState(["oemData"]),
    },
    created(){
         const _this=this;
        document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            if (e1 && e1.keyCode == 13) { 
                _this.onSubmit('ruleForm')
            } 
        }    
    },
    watch:{
        keyboards(val){
            this.formVal = this.form[val]
        }
    },
    methods: {
        ...mapActions(['updateShopInfoAction']),
        ...mapMutations(['updateCashier','updatePermission']),
        onSubmit(formName) {
            // bound.stringToVoice("支付宝到账100万元");
            const message = this.form;
            const _this = this;
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    login(message)
                        .then((res) => {
                            if (res.data.status === 200) {
                                Cookies.set("Access-Token", res.data.data.token, {expires: 7,});
                                this.updatePermission(res.data.data.userPermissions)
                                if (_this.form.checked === true) {
                                    Cookies.set("username", _this.form.username, { expires: 180 });
                                    Cookies.set("shop_number", _this.form.shop_number, { expires: 180 });
                                    const arr=JSON.parse(localStorage.getItem('Ar'))?JSON.parse(localStorage.getItem('Ar')):[_this.form.shop_number];
                                    const arrs=JSON.parse(localStorage.getItem('user'))?JSON.parse(localStorage.getItem('user')):[_this.form.username];
                                    if( arr.indexOf(_this.form.shop_number) == -1){
                                        arr.push(_this.form.shop_number);
                                        arrs.push(_this.form.username);
                                    }
                                    localStorage.setItem('Ar', JSON.stringify(arr))
                                    localStorage.setItem('user', JSON.stringify(arrs))
                                } else if(_this.form.checked === false){
                                    Cookies.remove("username");
                                    Cookies.remove('shop_number');
                                }
                                localStorage.setItem('username',_this.form.username)
                                this.$router.push("/about");
                                this.socketApi.websocketsend({"key": "login",value:{
                                    token: Cookies.get("Access-Token"),
                                    terminal_no: this.form.terminal_no
                                } });
                                shop_detail().then(shopInfo => {
                                    console.log('shopInfo',shopInfo);
                                    if(shopInfo.data.status === 200){
                                        this.updateShopInfoAction(shopInfo.data.data.shop)
                                    }
                                })
                                getScreenSetting({key:'goods_model'}).then(res => {
                                    if(res.data.status === 200){
                                        const values=res.data.data.values
                                        this.updateCashier(values.cashier_mode_type || 1)
                                    }
                                })
                                this.socketApi.websocketOpen()
                            }
                        })
                        .catch((error) => {
                            return false;
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
            
        },
        focusUsername() {
            this.keyboards = "username";
        },
        focusPassword() {
            this.keyboards = "password";
        },
        focusNumber() {
            this.keyboards = "shop_number";
        },
        codeChange(val){
            this.$set(this.form ,this.keyboards , val)
        },
        onChange(value){
            const arr=JSON.parse(localStorage.getItem('Ar'))?JSON.parse(localStorage.getItem('Ar')):[]
            const activeIndex =arr.indexOf(value);
            if(activeIndex !== -1){
                this.form.username=JSON.parse(localStorage.getItem('user'))[activeIndex]
            }
        },
        onChanges(value){
            const arr=JSON.parse(localStorage.getItem('user'))?JSON.parse(localStorage.getItem('user')):[]
            const activeIndex =arr.indexOf(value);
            if(activeIndex !==-1){
                this.form.shop_number=JSON.parse(localStorage.getItem('Ar'))[activeIndex] 
            } 
        },
        goPage(){
            window.open('http://beian.miit.gov.cn/')
        }
    },
    mounted() {
        if( Cookies.get("username") && Cookies.get("password") && Cookies.get("shop_number")){
            this.form.checked=true;
        }     
        //this.form.terminal_no = '74D435498A22'
        this.form.version = localStorage.getItem('FOOD_TYPE') || 'restaurant'
        this.form.terminal_no = bound.getMacInfo().replace(/-/g,'')
        this.verstion = bound.verstion()
    },
    beforeDestroy() {
        document.onkeydown = ''
    },
};
</script>
<style lang="less" scoped>
.login{
    .verstion{
        height: 4vh;
        line-height: 4vh;
        text-align: right;
        padding-right: 1.5vw;
        font-size: 1.8vh;
        color: #a6a6a6;
    }
    .myLogin{
        width: 28vw;
        margin: 1vh auto 3vh;
        .img{
            height:7.5vh;
            display: flex;
            justify-content: center;
            img{
                height: 100%;
            }
        }
        .login_box{
            margin-top: 3vh;
            /deep/.ant-input{
                height: 6vh;
            }
            .OkBtn{
                width:100%;
                margin-top: 2vh;
            }
            /deep/.ant-checkbox-checked .ant-checkbox-inner{
                background-color:#009688 ;
                border-color:#009688 ;
                border-color: #009688;
            }
        }
    }
    .code_box{
        display: flex;
        justify-content: center;
    }
    .bottom{
        color: #a6a6a6;
        text-align: center;
        position: fixed;
        bottom: 1vh;
        width: 100vw;
        height: 6vh;
        line-height: 6vh;
        font-size: 2vh;
        span{
            padding:0 2vw;
        }
        .beian:hover{
            // color: #d95e58;
            cursor: pointer;
        }
    }
}
</style>
