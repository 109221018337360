import Cookies from "js-cookie";
var websock = null;
var global_callback = null;
import store from '@/store'
import { message } from 'ant-design-vue';
import { addDB , getDBByKey , deleteAWeekAgo} from './indexDB'

function getWebIP() {
    var curIP = window.location.hostname;
    return curIP;
}
let errorNum = 3

function initWebSocket() { //初始化weosocket
    //ws地址
    var wsuri = process.env.VUE_APP_PATH;
    //  var wsuri = 'ws://192.168.0.127:9501'
    websock = new WebSocket(wsuri);
    websock.onmessage = function(e) {
        websocketonmessage(e);
        // console.log('dfsdfsdfsd',e);
    }
    websock.onclose = function(e) {
        websocketclose(e);
    }
    websock.onopen = function() {
        // message.success('网络连接成功')
        store.commit('updateWS_OK', true)
            // store.commit('updateRefresh',true)
        console.log("连接成功");
        websocketOpen();
    }

    //连接发生错误的回调方法
    websock.onerror = function() {
        console.log("WebSocket连接发生错误");
        errorNum--
        if (errorNum > 0) {
            // message.error("网络连接失败，请检查网络，10秒后将再次连接");
            store.commit('updateWS_OK', false)
            setTimeout(() => {
                initWebSocket()
            }, 10000)
        } else {
            // message.error("网络连接失败，请连接网络后再次刷新");
            store.commit('updateWS_OK', false)
        }

    }

}

// 实际调用的方法
function sendSock(agentData, callback) {
    global_callback = callback;
    if (websock.readyState === websock.OPEN) {
        //若是ws开启状态
        websocketsend(agentData)
    } else if (websock.readyState === websock.CONNECTING) {
        // 若是 正在开启状态，则等待1s后重新调用
        setTimeout(function() {
            sendSock(agentData, callback);
        }, 1000);
    } else {
        // 若未开启 ，则等待1s后重新调用
        setTimeout(function() {
            sendSock(agentData, callback);
        }, 1000);
    }
}

//数据接收
function websocketonmessage(e) {
    let json = JSON.parse(e.data);
    console.log('接收数据', json)
    // message.warning('接收数据: key = ' + json.key + '----- is_return = ' + json.is_return + '----- id = ' + json.id + '----- type = ' + json.type, 5)
    // let canPrint = true
    if(json.is_return == 1){
        let returnData = JSON.parse(JSON.stringify(json))
        getDBByKey('printed_ids','id', returnData.id).then(res => {
            console.log('读取---', res);
            if(res.code == 200 && res.data && res.data.id){
                returnData.old_key = json.key
                returnData.key = 'del_task_from_list'
                websocketsend(returnData)
            }else{
                addDB('printed_ids',{ id:returnData.id, time: +new Date()}).then(res => {
                    if(res.code == 200){
                        returnData.old_key = json.key
                        returnData.key = 'del_[task_from_list'
                        websocketsend(returnData)
                        if (json['key'] == 'print') {
                            bound.printListBtn(JSON.stringify(json))
                        }
                    }
                })
            }
        })
    }
    switch (json.key) {
        case "validation": //获取店铺名称
            if (json.value.status == 200) {
                store.commit('updateShopName', json.value.shop_name)
                    // console.log('store.state.shop_name', store.state.shop_name);
            } else if (json.value.status == 0) {
                message.error('token验证失败，请重新登录');
                let Ar = JSON.parse(localStorage.getItem('Ar'));
                let username = localStorage.getItem('username')
                let user = JSON.parse(localStorage.getItem('user'));
                let FOOD_TYPE = localStorage.getItem('FOOD_TYPE')
                let OEM = localStorage.getItem('OEM')
                let install=JSON.parse(localStorage.getItem('install'))
                let screen=JSON.parse(localStorage.getItem('screen'))
                let LSSP_printer_id=JSON.parse(localStorage.getItem('LSSP_printer_id'))
                let printReceipt = localStorage.getItem('printReceipt')
                localStorage.clear()
                localStorage.setItem('printReceipt', printReceipt)
                localStorage.setItem('LSSP_printer_id', LSSP_printer_id)
                localStorage.setItem('OEM', OEM)
                localStorage.setItem('FOOD_TYPE', FOOD_TYPE)
                localStorage.setItem('username', username)
                localStorage.setItem('Ar', JSON.stringify(Ar))
                localStorage.setItem('user', JSON.stringify(user))
                localStorage.setItem('install', JSON.stringify(install))
                localStorage.setItem('screen', JSON.stringify(screen))
                Cookies.remove('Access-Token')
                deleteAWeekAgo()
                bound.deleteCache()
                setTimeout(() => {
                    location.href = "/";
                }, 500)
            }
            break;
        case "logout": //其他终端登录被挤掉线
            message.error(json.value.message);
            let Ar = JSON.parse(localStorage.getItem('Ar'));
            let username = localStorage.getItem('username')
            let user = JSON.parse(localStorage.getItem('user'));
            let FOOD_TYPE = localStorage.getItem('FOOD_TYPE')
            let OEM = localStorage.getItem('OEM')
            let install=JSON.parse(localStorage.getItem('install'))
            let screen=JSON.parse(localStorage.getItem('screen'))
            let LSSP_printer_id=JSON.parse(localStorage.getItem('LSSP_printer_id'))
            let printReceipt = localStorage.getItem('printReceipt')
            localStorage.clear()
            localStorage.setItem('printReceipt', printReceipt)
            localStorage.setItem('LSSP_printer_id', LSSP_printer_id)
            localStorage.setItem('OEM', OEM)
            localStorage.setItem('FOOD_TYPE', FOOD_TYPE)
            localStorage.setItem('username', username)
            localStorage.setItem('Ar', JSON.stringify(Ar))
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('install', JSON.stringify(install))
            localStorage.setItem('screen', JSON.stringify(screen))
            Cookies.remove('Access-Token')
            deleteAWeekAgo()
            bound.deleteCache()
            setTimeout(() => {
                location.href = "/";
            }, 500)
            break;
        case "hearBeat": //心跳包  查看连接是否断开
            store.commit('updateWebsockTime', json.value.times)
            store.commit('updateWS_OK', true)
            break;
        default:
            store.commit('updateWSKey', json.key)
            store.commit('updateWSData', json)
            break;
    }

    if (json['key'] == 'print' && json.is_return != 1) {
        bound.printListBtn(JSON.stringify(json))

    } else if (json['key'] == 'printNotic') {
        message.error(json['values']['message'], 1.5);
    } else if (json['key'] == 'refresh_table') {
        const mp3 = json['values']['mp3'];
        store.commit('updateRefresh', mp3);
    }
}

//数据发送
function websocketsend(agentData) {
    const key = agentData && agentData.key;
    // message.success('发送数据' + key , 5)
    if(key && key != 'validation' && key !='heartBeat' && key != 'login'){
        const printList = JSON.parse(bound.showPrintList());
        const arr = [];
        printList.forEach(item => {
            arr.push(item.name)
        });
        switch (key) {
            case "diancaiAction": //送单
                agentData.value.printList = arr
                break;
            case "jiezhangAction": //完成结算
                agentData.value.printList = arr
                break;
            case "kedanAction": //客单
                agentData.value.printList = arr
                break;
            case "zhuancaiAction": //移菜
                agentData.value.printList = arr
                break;
            case "jiaobanAction": //交班
                agentData.value.printList = arr
                break;
        }
    }
    if (agentData) {
        console.log('发送数据', agentData);
        websock.send(JSON.stringify(agentData));
    }
}

//关闭
function websocketclose(e) {
    console.log("connection closed (" + e.code + ")");
    store.commit('updateWS_OK', false)
}

function websocketOpen(e) {
    if (Cookies.get('Access-Token') && Cookies.get('Access-Token') != '') {
        let actions = { 
            key: "validation",
            value: {
                token: Cookies.get('Access-Token') ,
                terminal_no: bound.getMacInfo().replace(/-/g,''),
                // terminal_no: '74D435498A20'
            } 
        };
        websocketsend(actions);
    }
}

initWebSocket();

export { sendSock, websocketOpen, websocketsend, websocketonmessage, initWebSocket }