let indexDB
let db
let objectStore

//初始化  打开/创建  数据库 、表
// dbName 数据库名字  version 版本号  storeName 表   keyPath 主键    index 表里要存的字段名数组 
const openDB = function (dbName, version, storeName, keyPath, index) {
    console.log('前端数据库');
    return new Promise((resolve, reject) => {
        //  兼容浏览器
        let indexedDB =
            window.indexedDB ||
            window.mozIndexedDB ||
            window.webkitIndexedDB ||
            window.msIndexedDB
        
        indexDB = indexedDB.open(dbName, version)
        // 操作成功
        indexDB.onsuccess = function (event) {
            db = event.target.result // 数据库对象
            resolve({code: 200, success: true, data: db, msg: '数据库打开成功!'})
        }
        // 操作失败
        indexDB.onerror = function (event) {
            resolve({code: 500, success: false, data: null, msg: '数据库打开失败!'})
        }
        // 创建表和索引
        indexDB.onupgradeneeded = function (event) {
            // 数据库创建或升级的时候会触发
            db = event.target.result // 数据库对象
            if (!db.objectStoreNames.contains(storeName)) {
                objectStore = db.createObjectStore(storeName, { keyPath: keyPath || 'id',autoIncrement: true }) // 创建表
                index.forEach(item => {
                    objectStore.createIndex(item, item, { unique: false }) // 创建索引
                })
            }
        }
    })
}


openDB('database',1,'printed_ids','new_id',['id','time']).then(res => {
    console.log('indexDB',res);
})

//添加
const addDB = (storeName,item) => {
    return new Promise((resolve, reject) => {
        const objectStore = db.transaction([storeName], 'readwrite').objectStore(storeName)
        let request = objectStore.add(item)
        // 操作成功
        request.onsuccess = () => {
            // resolve(request.result)
            resolve({code: 200, success: true, data: request.result, msg: '新增成功!'})
        }
        // 操作失败
        request.onerror = (event) => {
            // reject(event)
            resolve({code: 500, success: false, data: null, msg: '新增失败!'})
        }
    })
}

// 根据 游标 查询表内所有数据
const cursorGetData = function (storeName) {   
    let list = []
    let store = db.transaction(storeName, 'readwrite') // 事务
      .objectStore(storeName) // 仓库对象
    let request = store.openCursor() // 指针对象
    return new Promise((resolve, reject) => {
      request.onsuccess = function(e) {
        let cursor = e.target.result
        if (cursor) {
          // 必须要检查
          list.push(cursor.value)
          cursor.continue() // 遍历了存储对象中的所有内容
        } else {
          resolve({code: 200, success: true, data: list, msg: '查询成功'})
        }
      }
      request.onerror = function(e) {
        reject({code: 500, success: false, data: null, msg: '查询失败'})
      }
    })
}

//根据  key value 查
const getDBByKey = function(storeName,key,val) {
    return new Promise((resolve, reject) => {
        let transaction = db.transaction(storeName)
        let objectStore = transaction.objectStore(storeName)
        // 通过主键读取数据

        //根据 主键 查
        // let request = objectStore.get(key)

        //根据  key val 查
        let keyStr = objectStore.index(key)
        let request = keyStr.get(val)

        // 操作成功
        request.onsuccess = () => {
            // resolve(request.result)
            resolve({code: 200, success: true, data: request.result, msg: '查询成功!'})
        }
        // 操作失败
        request.onerror = (event) => {
            // reject(event)
            resolve({code: 500, success: false, data: null, msg: '查询失败!'})
        }
    })
}

//根据  主键  查
const getDBById = function(storeName, id) {
    return new Promise((resolve, reject) => {
        let transaction = db.transaction(storeName)
        let objectStore = transaction.objectStore(storeName)
        // 通过主键读取数据

        //根据 主键 查
        let request = objectStore.get(id)

        // 操作成功
        request.onsuccess = () => {
            // resolve(request.result)
            resolve({code: 200, success: true, data: request.result, msg: '查询成功!'})
        }
        // 操作失败
        request.onerror = (event) => {
            // reject(event)
            resolve({code: 500, success: false, data: null, msg: '查询失败!'})
        }
    })
}

//清空  表
const clearDB = function(storeName) {
    return new Promise((resolve, reject) => {
        let transaction = db.transaction(storeName, 'readwrite')
        let store = transaction.objectStore(storeName)
        let response = store.clear()
        resolve({code: 200, success: true, data: null, msg: '操作成功'})
        // 操作成功
        response.onsuccss = (event) => {
            resolve({code: 200, success: true, data: null, msg: '操作成功'})
        }
        // 操作失败
        response.onerror = (event) => {
            resolve({code: 500, success: false, data: null, msg: '操作失败'})
        }
    })
}

//根据 主键删除
const deleteDB = function( storeName, id) {
    let request = db.transaction([storeName], 'readwrite').objectStore(storeName).delete(id)
   
    return new Promise((resolve, reject) => {
      request.onsuccess = function(ev) {
        resolve({code: 200, success: true, data: null, msg: '删除成功'})
      }
   
      request.onerror = function(ev) {
        resolve({code: 500, success: false, data: null, msg: '删除失败'})
      }
    })
}

const deleteAWeekAgo = function(){   //删除一周前数据
    cursorGetData('printed_ids').then(res => {
        if(res.code == 200){
            let list = res.data
            list.forEach(item => {
                if((item.time + 604800000) < +new Date()){
                    deleteDB('printed_ids',item.new_id)
                }
            })
        }
    })
}
// setTimeout(() => {
//     getDBByKey('printed_ids','id',1654596).then(res => {
//         console.log('根据  key value 查---', res);
//     })
    // addDB('printed_ids',{ time: +new Date()}).then(res => {
    //     console.log('新增---',res);
    // })
    // clearDB('printed_ids').then(res => {
    //     console.log('清空---',res);
    // })
    // deleteDB('printed_ids',5).then(res => {
    //     console.log('根据id删除', res);
    // })
    // cursorGetData('printed_ids').then(res => {
    //     console.log('游标查询---',res);
    // })
    // deleteAWeekAgo('printed_ids')
// },1000)
export { getDBByKey , addDB , clearDB , deleteAWeekAgo }
