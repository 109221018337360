<template>
    <div class="fastKeycode">
        <input type="text" ref="keyInput" v-show="false" :value="codeVal"  @input="inputEvent"> 
        <div class="key_box">
            <div class="key_item" v-for="(item,i) in keyList" :key="i" @click="changeVal(item)">{{item}}</div>
        </div>
    </div>
</template>
<script>
export default {
    name:'fastKeycode',
    model:{
        prop:'codeVal',  // 对应到props
        event:'change'
    },
    props:{
        codeVal:{
            default:null,
            type:[String,Number]
        },
    },
    data(){
        return{
            daxie:true,
            keyList:[
                'Q','W','E','R','T','Y','U','I','O','P','1','2','3',
                    'A','S','D','F','G','H','J','K','L',  '4','5','6',
                    'Z','X','C','V','B','N','M','-',    '7','8','9',
                        '小写','空格','确定',            '0','.','删除',
            ]
        }
    },
    watch:{
        daxie(val){
            if(val){
                this.keyList = [
                    'Q','W','E','R','T','Y','U','I','O','P','1','2','3',
                      'A','S','D','F','G','H','J','K','L',  '4','5','6',
                        'Z','X','C','V','B','N','M','-',    '7','8','9',
                            '小写','空格','确定',            '0','.','删除',
                ]
            }else{
                this.keyList = [
                    'q','w','e','r','t','y','u','i','o','p','1','2','3',
                      'a','s','d','f','g','h','j','k','l',  '4','5','6',
                        'z','x','c','v','b','n','m','-',    '7','8','9',
                            '大写','空格','确定',            '0','.','删除',
                ]
            }
        }
    },
    methods:{
        changeVal(val){
            if(val == '确定'){
                this.$emit('okHandle')
            }else if(val == '大写'){
                this.daxie = true
            }else if(val == '小写'){
                this.daxie = false
            }else{
                let valData = ''
                if(val == '删除'){
                    valData = this.codeVal.slice(0 , this.codeVal.length - 1)
                }else if(val == '空格'){
                    valData = this.codeVal + ' '
                }else if(val == '.'){
                    let i = this.codeVal.indexOf('.')
                    console.log(i);
                    if(i < 0){
                        valData = this.codeVal + '.'
                    }else{
                        valData = this.codeVal
                        this.$message.warning('小数点不可多次输入')
                    }
                }else{
                    if(this.focus_select){
                        valData = val
                    }else{
                        valData = this.codeVal + val
                    }
                }
                this.$refs.keyInput.value = valData
                let evt = document.createEvent('HTMLEvents') 
                evt.initEvent('input', true, true)
                this.$refs.keyInput.dispatchEvent(evt)
            }
        },
        inputEvent(e){
            this.$emit('change',e.target.value)
        },
    }

}
</script>
<style lang="less" scoped>
.fastKeycode{
    .key_box{
        background: #d4d4d4;
        display: flex;
        flex-wrap: wrap;
        width: 102.1vh;
        padding: 1.8vh;
        border-radius: 1vh;
        .key_item{
            width: 7vh;
            height: 5.6vh;
            line-height: 5.6vh;
            text-align: center;
            font-size: 2.8vh;
            background: #f1f1f1;
            margin-right: 0.5vh;
            margin-bottom: 0.8vh;
            color: #666666;
            border-radius: 0.5vh;
            cursor: pointer;
            &:nth-child(10),&:nth-child(22),&:nth-child(33),&:nth-child(39){
                margin-right: 1.5vh;
            }
            &:nth-child(14),&:nth-child(22){
                width: 10.75vh;
            }
            &:nth-child(26),&:nth-child(33),&:nth-child(37){
                width: 14.5vh;
            }
            &:nth-child(38){
                width: 40.5vh;
            }
            &:nth-child(39){
                width: 18.5vh;
            }
            &:nth-child(37),&:nth-child(38),&:nth-child(39),&:nth-child(40),&:nth-child(41),&:nth-child(42){
                margin-bottom: 0;
            }
            &:focus, &:active{
                background: #D95E5830;
            }
        }
    }
    
}
</style>