<template>
    <div class="fastFoodPage">
        <div class="common_left" v-if="routeName !== 'quickCashier'">
            <div class="top">
                <div :class="activeName == item.name ? 'firstMenu active' : 'firstMenu'" v-for="item in firstMenuList" :key="item.name"
                @click="clickFirstMenu(item)">
                    <span :class="activeName == item.name ? ('iconfont ' + item.icon) : ('iconfont ' + item.icon)"></span>
                    <div>{{item.title}}</div>
                </div>
            </div>
            <div class="bottom">
                <div class="shuaixin" @click="againdata">
                    <span class="iconfont icon-shuaxin"></span>
                    <!-- <div>刷新</div> -->
                </div>
                <div>
                    <span class="iconfont icon-wifi" v-if="WS_OK"></span>
                    <span class="iconfont icon-duanwang" v-else></span>
                </div>
            </div>
        </div>
        <div class="menu_box" v-if="menuData && menuData.title">
            <div class="title">{{menuData.title }}</div>
            <div v-for="item in menuData.children" :key="item.name">
                <div v-if="item.children && item.children.length" class="secondary">
                    <div class="title" @click="openMenuHandle(item)" >
                        <span :class="item.children.findIndex(ele => ele.name == current) > -1 ? 'textColor' : ''">{{item.meta.title}}</span>
                        <a-icon type="right"  :class="item.children.findIndex(ele => ele.name == current) > -1 ? 'textColor' : ''"
                         :style="'font-size:1.3vh;transition:all 0.5s;' + (item.expand ? 'transform:rotate(90deg);' : 'transform:rotate(-90deg);')"/>
                    </div>
                    <div v-if="item.expand">
                        <div v-for="ele in item.children" :key="ele.name"  @click="clickMenu(ele)" :class="current == ele.name ? 'menu_3 textColor' : 'menu_3 color1'">
                            {{ele.meta.title}}
                        </div>
                    </div>
                </div>
                <div v-else @click="clickMenu(item)" :class="current == item.name ? 'menu textColor' : 'menu'">
                    {{item.meta.title}}
                </div>
             </div>
        </div>
        <router-view class="routerView"></router-view>
    </div>
</template>
<script>
import { mapState , mapActions } from "vuex";
import { toStore } from "@/request/api";

export default {
    name:'fastFoodPage',
    data(){
        return{
            firstMenuList:[
                {
                    path:'/yingye',
                    title:'收银',
                    icon:'icon-shouyin',
                    name: 'Yingye'
                },
                {
                    path:'/returnGoods',
                    name:'tuihuo',
                    title:'退货',
                    icon:'icon-tuihuo'
                },
                {
                    path:'/bill/flow',
                    title:'账单',
                    icon:'icon-liushui',
                    name:'bill'
                },
                {
                    path:'/jiaoban',
                    title:'交班',
                    icon:'icon-banci',
                    name:'jiaoban'
                }
            ],
            menuData:{
                title:'',
                children:[]
            },
            current:'',
            version:"",
            routeName:'' ,
            openMenu:{}
        }
    },
    computed: {
        ...mapState(["shop_name", 'topTabList' ,'activeName','WS_OK','cashier' , 'permission']),
    },
    mounted(){
        this.getData()
        this.routeName = this.$route.name
        let item = {
            path: this.cashier == 1 ? '/yingye' : '/quickCashier',
            title:'收银',
            icon:'icon-shouyin',
            name: this.cashier == 1 ? 'Yingye' : 'quickCashier'
        }
        this.$set(this.firstMenuList , 0 , item)
    },
    watch:{
        $route(newVal,val){
            this.routeName = newVal.name
            this.getData()
        },
        cashier(val){
            let item = {
                path: val == 1 ? '/yingye' : '/quickCashier',
                title:'收银',
                icon:'icon-shouyin',
                name: val == 1 ? 'Yingye' : 'quickCashier'
            }
            this.$set(this.firstMenuList , 0 , item)
        }
    },
    methods:{
        getData(){
            let route = this.$route
            this.current = route.name
            if(route.meta && route.meta.hindMenu){
                this.menuData = {
                    title:'',
                    children:[]
                }
            }else{
                let routerList = this.$router.options.routes[1].children[1].children
                if(route.meta && route.meta.Level == 3){
                    let i = routerList.findIndex(item => item.name == route.meta.parent)
                    this.getMenuData(routerList[i] , routerList)
                }else{
                    this.getMenuData(route , routerList)
                }
            }
        },
        getMenuData(route , routerList){
            let arr = []
            let brr=[]
            routerList.forEach(item => {
                if(item.meta.parent == route.meta.parent){
                    if(item.children && item.children.length){
                        item.expand = this.openMenu[item.name]
                    }
                    arr.push(item)
                }
            });
            this.version=localStorage.getItem('FOOD_TYPE')
            // arr.forEach(item=>{
            //     if(item.meta.title !='商品模式设置' && this.version !='shops'){
            //         brr.push(item)
            //     }else if(this.version =='shops'){
            //         brr=arr
            //     }
            // })
            this.menuData.title = route.meta.parentTitle
            this.menuData.children = arr
            if(this.menuData.title === '设置'){
                this.menuData.children.push({
                    meta:{
                        title:'跳转后台',
                    },
                    path:'/Backstage',
                    name:'Backstage'
                })
            }
        },
        openMenuHandle(item){
            this.openMenu[item.name] = !item.expand
            this.$set(item , 'expand' , !item.expand)
        },
        ...mapActions(['updateTabListAction','activeNameAction']),
        vuexHandle(data){
            this.activeNameAction(data.name)
            let arr = JSON.parse(JSON.stringify(this.topTabList))
            let i = arr.findIndex(item => item.name == data.name)
            if(i < 0){
                data.isDetele = true
                arr.push(data)
                this.updateTabListAction(arr)
            }
        },
        clickFirstMenu(item){
            if(item.name == "tuihuo" && this.permission.indexOf('/clerk/goods/goodsRefund') < 0){
                this.$message.warning('您还没有此权限')
                return
            }
            this.vuexHandle(item)
            this.$router.push(item.path)
        },
        clickMenu(item){
            if(item.name === 'Backstage'){
                this.jumpStore()
            }else{
                this.$router.push(item.path)
            }
        },
        jumpStore(){
            toStore().then((result) => {
                if (result.status === 200) {
                    const token=result.data.data.token && result.data.data.token;
                    window.open(process.env.VUE_APP_HOU+encodeURI(token),'')
                } 
            })
            .catch((result) => {
                return false;
            });
        },
        againdata() {
            this.$confirm({
                title: "提示",
                content: "确定要重新加载页面么?",
                okText: "确定",
                cancelText: "取消",
                onOk() {
                    window.location.reload();
                },
                onCancel() {},
            });
        },
    }
}
</script>
<style lang="less" scoped>
.fastFoodPage{
    display: flex;
    height: 94.8vh;
    font-size: 1.8vh;
    .menu_box{
        width: 8.8vw;
        // text-align: center;
        border-right: 1px solid #e5e5e5;
        .title{
            font-size: 2.1vh;
            font-weight: 700;
            color: #505050;
            margin: 3vh 0.8vw 4vh;
        }
        .menu{
            margin: 3vh 0.8vw;
            cursor: pointer;
        }
        .secondary{
            .title{
               font-size: 1.8vh; 
               font-weight: normal;
               margin: 3vh 0.8vw 2vh 0.8vw;
               display: flex;
               justify-content: space-between;
               align-items: center;
               >span{
                   margin-right: 0.6vw;
               }
            }
            .menu_3{
                font-size: 1.6vh;
                margin:0 0 2vh 1.3vw;
                cursor: pointer;
            }
            .color1{
                color: #999999;
            }
        }
    }
    .routerView{
        flex: 1;
        overflow: auto;
        &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width : 3px;  /*高宽分别对应横竖滚动条的尺寸*/
            height: 3px;
        }
        &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 6px;
            background   : #AAAAAA;
        }
        &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            border-radius: 6px;
            background   : #CCCACA;
        }
    }
}
</style>
