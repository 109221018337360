import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Antd from 'ant-design-vue';
import axios from 'axios'
import 'ant-design-vue/dist/antd.css';
import '@/assets/fonts/iconfont.css'
// import mixin from './public/mixin';
import * as socketApi from './public/mixin';
import * as indexDB from './public/indexDB';
import moment from 'moment';
import locale from 'ant-design-vue/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import '@/assets/topTabsIcon/iconfont.css'
import { getStrNum, getTofixed, getInteger } from './public/common.js'
Vue.prototype.getStrNum = getStrNum;
Vue.prototype.getTofixed = getTofixed;
Vue.prototype.getInteger = getInteger


let foodType = 'feast'
let FOOD_TYPE = localStorage.getItem('FOOD_TYPE')
if (FOOD_TYPE) {
    foodType = FOOD_TYPE
} else {
    let hrefURL = window.location.href
    if (hrefURL.indexOf('restaurant') > -1) { // 轻餐
        foodType = 'restaurant'
    } else if (hrefURL.indexOf('shops') > -1) { // 母婴/便利/生鲜
        foodType = 'shops'
    } else if (hrefURL.indexOf('Tea') > -1) { // 奶茶烘焙
        foodType = 'Tea'
    } else { // 重餐
        foodType = 'feast'
    }
    localStorage.setItem('FOOD_TYPE', foodType)
}

if (foodType === 'feast') { // 重餐
    import ('./assets/commonCss/chineseFood.less')
} else if (foodType === 'shops') { // 母婴/便利/生鲜
    import ('./assets/commonCss/shops.less')
} else { //轻餐    // 奶茶烘焙
    import ('./assets/commonCss/fastFood.less')
}


moment.locale('zh-cn');
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(Antd);
Vue.prototype.socketApi = socketApi
Vue.prototype.indexDB = indexDB

import { message } from 'ant-design-vue'
Vue.prototype.$message = message;
message.config({
    duration: 2, // 持续时间
    // top:`50px`, // 到页面顶部距离
    // maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
})


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')