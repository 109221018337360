import Vue from 'vue'
import Vuex from 'vuex'

import fast_food from './modules/fast_food'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shop_name:'',
    shop_id:'',
    shop_info:{},
    topTabList:[{
      title:'主页面',
      path:'/about',
      name: 'About',
      isDetele:false
    }],
    activeName:'About',
    websockKey:'',
    websockData:{},
    websockTime:'',
    WS_OK:false,
    refresh:0,
    speech_path:'',
    oemData:{},
    cashier: 1,  //  1 普通收银   2 快速收银
    permission:[] //用户权限
  },
  mutations: {
    updateShopName(state,value){    //修改shop_name
      state.shop_name=value;
    },
    updateShopId(state,value){    //修改shop_id
      console.log(value)
      state.shop_id=parseInt(value);
    },
    updateShopInfo(state,value){    //修改shop_info
      state.shop_info=value;
    },
    updateTabList(state,value){
      state.topTabList = value;
    },
    updateActiveName(state,value){
      state.activeName = value;
    },
    updateWSKey(state,value){
      state.websockKey = value;
    },
    updateWSData(state,value){
      state.websockData = value;
    },
    updateWebsockTime(state,value){
      state.websockTime = value;
    },
    updateWS_OK(state,value){
      state.WS_OK = value;
    },
    updateRefresh(state,value){
      state.refresh=state.refresh+1;
      state.speech_path=value;
    },
    updateOemData(state,data){
      state.oemData = data;
      localStorage.setItem('OEMDATA',JSON.stringify(data))
    },
    updateCashier(state,data){
      state.cashier = data;
    },
    updatePermission(state,data){
      state.permission = data;
      localStorage.setItem('permission',JSON.stringify(data))
    }
  },
  actions: {
    updateTabListAction ({ commit }, data) {//修改顶部tabs列表
      commit('updateTabList',data)
      localStorage.setItem('topTabList',JSON.stringify(data))
    },
    activeNameAction({ commit }, data){
      commit('updateActiveName',data)
      localStorage.setItem('activeName',data)
    },
    updateShopInfoAction({ commit }, data){
      commit('updateShopInfo',data)
      localStorage.setItem('shop_info',JSON.stringify(data))
    },
  },
  getters:{

  },
  modules: {
    fast_food
  }
})
