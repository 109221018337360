<template>
<div class="login">
    <touTop />

    <div class="login_cent">版本号：{{verstion}}</div>
    <div class="main">
        <div class="myLogin">
            <div class="img">
                 <img :src="oemData.login_img_url" alt class="login_bos" />
                 <div class="zhongduan">终端:{{form.terminal_no}}</div>
            </div>
           
            <!-- <a-divider type="vertical" class="myDivder" /> -->

            <div class="login_box">
                <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item ref="shop_number" prop="shop_number">
                        <a-auto-complete   v-model="form.shop_number" placeholder="请输入门店号" @focus="focusNumber()" :data-source="dataSource"  @change="onChange"   :dropdownMenuStyle="{width: '16vw'}" dropdownClassName="certain-category-search-dropdown" />
                        <!-- <template slot="dataSource">
                            <a-select-option v-for="group in dataSource" :key="group.username">
                                <span >{{group.shop_number}}</span>
                            </a-select-option>
                        </template> -->
                    </a-form-model-item>
                    <a-form-model-item ref="username" prop="username">
                        <a-auto-complete v-model="form.username" placeholder="请输入账号/手机号" @focus="focusUsername()" :data-source="userArr"  @change="onChanges"/>
                    </a-form-model-item>
                    <a-form-model-item ref="password" prop="password">
                        <a-input type="password" v-model="form.password" placeholder="请输入密码" @focus="focusPassword()" />
                    </a-form-model-item>
                    <a-checkbox class="checkbox" v-model="form.checked" ref="checked" prop="checked">记住我</a-checkbox>
                    <a-form-model-item>
                        <a-button class="btnClick" type="primary" @click="onSubmit('ruleForm')">登录</a-button>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>
        <!-- <div class="mendianmodal">
            <a-modal title="请选择门店" :visible="visibleDian" @ok="handleOk" @cancel="handleCancel" ok-text="进入" cancel-text="取消" :dialog-style="{ top: '180px' }">
                <span>门店名称:</span>
                <span>
                    <a-select default-value="请选择" style="width: 2rem; height: 0.36rem; margin-left: 0.2rem" @change="handleChange">
                        <a-select-option :value="`${item.shop_id}+${item.shop_name}`" v-for="item in menDianList" :key="item.shop_id">
                            {{ item.shop_name }}
                        </a-select-option>
                    </a-select>
                </span>
                <template slot="footer">
                    <a-button type="primary" @click="handleOk">进入</a-button>
                </template>
            </a-modal>
        </div> -->
         
    </div>
    <keyCode v-on:updatekey="GetKeyVal" :keyboards="keyboards" :form="form" :onSubmit="onSubmit"></keyCode>
  
    <Bottom/>
</div>
</template>
<script>
import { login ,  shop_detail} from "../request/api";
import keyCode from "../components/keycode/keycode";
import lunBo from "../components/lunbo/lunbo.vue";
import Cookies from "js-cookie";
import touTop from "../components/touTop/touTop.vue";
import Bottom from "../components/bottom/bottom.vue";
import {Base64} from 'js-base64';
import { mapState , mapActions , mapMutations } from "vuex";

export default {
    name: "Home",
    data() {
        return {
            verstion:'',
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            show: true,
            showcate: true,
            visible: false,
            // websock:Object,
            form: {
                username: Cookies.get("username") ? Cookies.get("username") : "",//用户名
                // password: Cookies.get("password")? Base64.decode(Cookies.get("password")) : "", //用户名密码form存储
                shop_number:Cookies.get("shop_number") ? Cookies.get("shop_number") : "", //门店号
                password: "", //用户名密码form存储
                checked:true,
                terminal_no:''
            },
            vauleInpt: "",
            rules: {
                username: [{
                    required: true,
                    message: "请输入账号",
                    trigger: "change",
                }, ],
                password: [{
                    required: true,
                    message: "请输入密码",
                    trigger: "change",
                }],
                shop_number: [{
                    required: true,
                    message: "请输入门店号",
                    trigger: "change",
                }],
            },
            socket: "",
            keyboards: "username",
            // visibleDian: false, //门店对话框是否显示
            menDianList: [], //门店列表
            shop_id: "", //门店id
            shop_name: "", //门店名称   
            dataSource:JSON.parse(localStorage.getItem('Ar'))?JSON.parse(localStorage.getItem('Ar')):[],   //门店号储存
            userArr:JSON.parse(localStorage.getItem('user'))?JSON.parse(localStorage.getItem('user')):[],   //用户名储存
        };
    },
    components: {
        lunBo,
        keyCode,
        touTop,
        Bottom,
    },
    created(){
         const _this=this;
        document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            if (e1 && e1.keyCode == 13) { 
              _this.onSubmit('ruleForm')
            } 
        }    
    },
    methods: {
        ...mapActions(['updateShopInfoAction']),
        ...mapMutations([ 'updatePermission' ]),
        onSubmit(formName) {
            const message = this.form;
            const _this = this;
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    login(message)
                        .then((res) => {
                            if (res.data.status === 500) {
                                // _this.visibleDian = false;
                                // alert("登录失败, 该用户不存在或已删除");
                            } else if (res.data.status === 200) {
                                //  _this.visibleDian=true;
                                Cookies.set("Access-Token", res.data.data.token, {expires: 7,});
                                this.updatePermission(res.data.data.userPermissions)
                                // this.shop_id=res.data.data.userId;
                                
                                if (_this.form.checked === true) {
                                    Cookies.set("username", _this.form.username, { expires: 180 });
                                    // Cookies.set("password", Base64.encode(_this.form.password), { expires: 180 });
                                    Cookies.set("shop_number", _this.form.shop_number, { expires: 180 });
                                    const arr=JSON.parse(localStorage.getItem('Ar'))?JSON.parse(localStorage.getItem('Ar')):[_this.form.shop_number];
                                    const arrs=JSON.parse(localStorage.getItem('user'))?JSON.parse(localStorage.getItem('user')):[_this.form.username];
                                    if( arr.indexOf(_this.form.shop_number) == -1){
                                        arr.push(_this.form.shop_number);
                                        arrs.push(_this.form.username);
                                    }
                                    localStorage.setItem('Ar', JSON.stringify(arr))
                                    localStorage.setItem('user', JSON.stringify(arrs))
                                } else if(_this.form.checked === false){
                                    Cookies.remove("username");
                                    // Cookies.remove("password");
                                    Cookies.remove('shop_number');
                                }
                                localStorage.setItem('username',_this.form.username)
                                // localStorage.setItem('jiaoban',false)
                                this.$router.push("/about");
                                this.socketApi.websocketsend({"key": "login",value:{
                                    token: Cookies.get("Access-Token"),
                                    terminal_no: this.form.terminal_no
                                } });
                                //console.log(JSON.stringify({"key": "login",value:{token: Cookies.get("Access-Token")} }))                          
                                // _this.menDianList = res.data.data.shopInfo;
                                // console.log(JSON.stringify(_this.menDianList))
                                shop_detail().then(shopInfo => {
                                    console.log('shopInfo',shopInfo);
                                    if(shopInfo.data.status === 200){
                                        this.updateShopInfoAction(shopInfo.data.data.shop)
                                    }
                                })
                                this.socketApi.websocketOpen()
                            }
                        })
                        .catch((error) => {
                            return false;
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
            
        },
        // inputName(){
        //   this.setDate=1
        //   // console.log(this.setDate)
        // },
        // inputPassword(){
        //   this.setDate=2
        //   // console.log(this.setDate)
        // },
        ///本机设置
        // setUp() {
        //   this.visible = true;
        // },
        // handleOk(e) {
        //   console.log(e);
        //   this.visible = false;
        // },

        // gongGao() {
        //   this.$info({
        //     title: "提示",
        //     content: "暂无新公告",
        //     okText: "关闭",
        //     onOk() {}
        //   });
        // },
        ///重新加载
        // againdata() {
        //   this.$confirm({
        //     title: "提示",
        //     content: "确定要重新加载页面么?",
        //     okText: "确定",
        //     cancelText: "取消",
        //     onOk() {
        //       window.location.reload();
        //     },
        //     onCancel() {}
        //   });
        // },
        ///写字板
        // dataxie() {
        //   this.$info({
        //     title: "提示",
        //     content: "功能暂未开放,敬请期待",
        //     okText: "确定",
        //     onOk() {}
        //   });
        // },
        ///远程
        // datayuan() {
        //   this.$info({
        //     title: "提示",
        //     content: "功能暂未开放,敬请期待",
        //     okText: "确定",
        //     onOk() {}
        //   });
        // },
        // delCode() {
        //   this.form.name = this.form.name.slice(0, this.form.name.length - 1);
        //   this.form.password = this.form.password.slice(
        //     0,
        //     this.form.password.length - 1
        //   );
        // },
        // codeKong() {
        //   if(this.setDate==1){
        //       this.form.name = this.form.name += " ";
        //   }else if(this.setDate==2){
        //       this.form.password=this.form.password+=" "
        //   }

        // },
        GetKeyVal(val) {
            if (this.keyboards === "username") {
                this.form.username = val;
            } else if (this.keyboards === "password") {
                this.form.password = val;
            }else if(this.keyboards === "shop_number"){
                this.form.shop_number = val;
            }
            this.$refs.ruleForm.validateField(this.keyboards)
        },
        focusUsername() {
            this.keyboards = "username";
        },
        focusPassword() {
            this.keyboards = "password";
        },
        focusNumber() {
            this.keyboards = "shop_number";
        },
        onChange(value){
            const arr=JSON.parse(localStorage.getItem('Ar'))?JSON.parse(localStorage.getItem('Ar')):[]
            const activeIndex =arr.indexOf(value);
            if(activeIndex !== -1){
                this.form.username=JSON.parse(localStorage.getItem('user'))[activeIndex]
            }
        },
        onChanges(value){
            const arr=JSON.parse(localStorage.getItem('user'))?JSON.parse(localStorage.getItem('user')):[]
            const activeIndex =arr.indexOf(value);
            if(activeIndex !==-1){
                this.form.shop_number=JSON.parse(localStorage.getItem('Ar'))[activeIndex] 
            } 
        }
        // handleOk() {
        //     //点击门店关闭
        //     // this.visibleDian = false;
        //     this.menDianListRequest();
        // },
        // handleCancel(e) {
        //     //点击开启选择门店
        //     this.visibleDian = false;
        // },
        // menDianListRequest() {
        //     //门店列表请求
        //     const message = { shop_id: this.shop_id };

        //     menDian(message)
        //         .then((res) => {
        //             if (res.data.status === 200) {
        //                 // this.$router.push("/about");
        //             }
        //         })
        //         .catch((error) => {});
        // },
        // handleChange(value) {
        //     //点击门店列表
        //     this.shop_id = value.split("+")[0];
        //     this.shop_name = value.split("+")[1];
        //     this.$store.commit("updateShopName", value.split("+")[1]);
        //     this.$store.commit("updateShopId", value.split("+")[0]);
        // },
    },
    computed: {
        ...mapState(["oemData"]),
    },
    mounted() {
        if( Cookies.get("username") && Cookies.get("password") && Cookies.get("shop_number")){
            this.form.checked=true;
        }     
        // this.form.terminal_no = '74D435498A20'
        this.form.version = localStorage.getItem('FOOD_TYPE') || 'feast'
        this.form.terminal_no = bound.getMacInfo().replace(/-/g,'')
        this.verstion = bound.verstion()
    },
    updated() {
    },
    destroyed() {       
    },
};
</script>
<style lang="less" scoped>
* {
    padding: 0;
    margin: 0;
}
/deep/.ant-modal-footer {
    text-align: right;
}
/deep/.ant-modal-root .ant-modal-content {
    background-color: rgba(237, 238, 242, 1);
}
/deep/.ant-modal-root .ant-modal-header {
    background-color: rgba(237, 238, 242, 1);
}
/deep/.ant-btn-primary {
    background-color: rgba(255, 102, 0, 1) !important;
    width: 4.29vw;
    height: 3.22vh;
    line-height: 3.22vh;
    border-color: rgba(255, 102, 0, 1) !important;
}
/deep/.ant-checkbox-inner {
    background-color: #3a4958;
}
.login .ant-form-item {
    margin-bottom: 1.11vh;
}
.login {
    width: 100%;
    height: 100%;
    position: fixed;
    background: #2e3e4e;

    .login_cent {
        font-size: 1.6vh;
        line-height: 3.33vh;
        color: #a6a6a6;
        text-align: right;
        padding-right: 1.2vw;
    }
    .bottom {
        display: flex;
        width: 100%;
        height: 3.22vh;
        background: #2e3e4e;
        border-top: 1px solid #3a4958;
        position: fixed;
        margin-top: 10%;
        p {
            margin-top: 2.14vh;
            margin-right: 0.54vw;
            color: #7998ab;
            span:nth-of-type(2) {
                color: white;
                cursor: pointer;
            }
        }
        .p {
            margin-left: 35%;
        }
    }
    .ant-btn-primary {
        background: #42505f;
        border-color: #42505f;
        font-weight: bold;
        font-size: 2vh;
    }
    .ant-btn-primary:hover {
        background: #adadad;
    }
    .main {
        width: 100%;
        display: flex;
        margin-bottom: 1.11vh;
        justify-content: center;
    }
    .myLogin {
        border-radius: 1.11vh;
        width: 39vw;
        height: 36vh;
        margin: 2.22vh;
        box-shadow: 0.11vh 0.11vh 0.55vh 0.55vh rgba(0, 0, 0, 0.26);
        display: flex;
    }
    .img{
        width: 18vw;
        padding: 6vh 2vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        img{
            width: 9vw;
        }
        .zhongduan{
            width: 100%;
            background: #42505f;
            text-align: center;
            font-weight: 700;
            color: #a7a7a7;
            font-size: 1.3vw;
            border-radius: 0.5vh;
        }
    }
    
    .login_bos {
        width: 8.5vw;
      
    }
    /deep/.ant-form-item-control {
        line-height: 0vh;
    }
    .login_box {
        width: 21vw;
        margin: 4vh 1vh 4vh 0vh;
        border-left: 2px solid #37485a;
        padding: 0vh 3vh;

        .ant-input {
            width: 16vw !important;
            color: white;
            height: 5vh!important;
            background-color: #42505f !important;
            border: 1px solid #42505f!important;
            font-size: 1.8vh!important;
            padding-left:1vw!important;
        }
        .ant-input:hover {
            border: 0.1vh solid orange;
        }
        .ant-btn-primary {
            width: 100%;
        }
        .checkbox {
            color: white;
        }
        .btnClick {
            width: 16vw;
            background: #ff6600;
            font-weight: 300;
            height: 5vh;
            font-size: 1.8vh;
            margin-top: 1vh;
        }
    }
}
.ant-modal-root .ant-modal-content {
    width: 22.22vw;
}
.ant-modal {
    width: 22.22vw !important;
}
.ant-modal-title {
    font-size: 1.8vh !important;
    line-height: 3.11vh !important;
}
.ant-modal-header {
    padding-top: 3.78vh !important;
}
.ant-modal-body {
    padding-top: 0 !important;
    padding-left: 1.29vw !important;
    padding-right: 0.27vw !important;
    font-size: 1.6vh;
}
 /deep/.ant-input {
    width: 16vw !important;
    color: white !important;
    height: 5vh !important;
    background-color: #42505f !important;
     border:1px solid #42505f !important;
    font-size: 1.8vh !important;
    padding-left:1vw !important;
}
/deep/.ant-col-14{
    width:16vw;   
}
/deep/.ant-select-search--inline{
    background: #2e3e4e !important;
}

</style>
