const fast_food = {
    state: {
        cartList: [], //购物车列表
        pendingOrder: [], //挂单
        currentMember: {}, //当前会员
        weightGoods: {}, //称重商品
        orderInfo: {},
        returnData: {},
        returnGoods: [],
        returnMember: {}
    },
    mutations: {
        set_cartList(state, data) {
            state.cartList = data
            localStorage.setItem('cartList', JSON.stringify(data))
            // const screenObject= JSON.parse(localStorage.getItem('screen'))?JSON.parse(localStorage.getItem('screen')):{use:2,com:undefined,potter:undefined,}
            // const com=screenObject.com;
            // const potter=parseFloat(screenObject.potter);
            // console.log(com)
            // console.log(potter)
            // if(data && data.length>0){
            //     let money = 0
            //     data.forEach(item => {
            //         money += item.goods_price * 1 * item.total_num
            //     });
            //     money = money.toFixed(2)  
            //     // bound.vScreen('COM1',2400,money,false)
            // }else{
            //     // bound.vScreen('COM1',2400,"0.00",false)
            // }
        },
        set_pendingOrder(state, data) {
            state.pendingOrder = data
            localStorage.setItem('pendingOrder', JSON.stringify(data))
        },
        set_currentMember(state, data) {
            state.currentMember = data
            localStorage.setItem('currentMember', JSON.stringify(data))
        },
        set_weightGoods(state, data) {
            state.weightGoods = data
        },
        set_orderInfo(state, data) {
            state.orderInfo = data
            localStorage.setItem('orderInfo', JSON.stringify(data))
        },
        set_returnData(state, data) {
            state.returnData = data
            localStorage.setItem('returnData', JSON.stringify(data))
        },
        set_returnGoods(state, data) {
            state.returnGoods = data
            localStorage.setItem('returnGoods', JSON.stringify(data))
        },
        set_returnMember(state, data) {
            state.returnMember = data
            localStorage.setItem('returnMember', JSON.stringify(data))
        },
    },
    actions: {

    }
}

export default fast_food