import fastFoodLayout from '@/components/layouts/fastFoodLayout.vue'
import fastFoodPage from '@/components/layouts/fastFoodPage.vue'
import Login from '@/viewsFastFood/Login.vue'
const RouteView = {
    name: 'RouteView',
    render: (h) => h('router-view')
}
const fastFood = [{
        path: '/',
        name: 'Login',
        component: Login
    },

    //快餐 烘焙
    {
        path: '/fastFood',
        name: 'fastFood',
        redirect: '/about',
        component: fastFoodLayout,
        children: [{
                path: '/about',
                name: 'About',
                component: resolve => require(['../viewsFastFood/About.vue'], resolve),
            },
            {
                path: '/fastFoodPage',
                name: 'fastFoodPage',
                redirect: '/yingye',
                component: fastFoodPage,
                children: [
                    {
                        path: '/yingye',
                        name: 'yingye',
                        meta: { hindMenu: true, title: '营业' },
                        component: resolve => require(['../viewsFastFood/yingye/index.vue'], resolve),
                    },
                    {
                        path: '/quickCashier',
                        name: 'quickCashier',
                        meta: { hindMenu: true, title: '快速收银' },
                        component: resolve => require(['../viewsFastFood/yingye/quickCashier.vue'], resolve),
                    },
                    {
                        path: '/system',
                        name: 'system',
                        meta: { hindMenu: false, title: '会员管理', parent: 'mis', parentTitle: '会员管理' },
                        component: resolve => require(['@/views/mis/modules/System.vue'], resolve),
                    },
                    {
                        path: '/turnover',
                        name: 'turnover',
                        meta: { hindMenu: false, title: '充值明细', parent: 'mis', parentTitle: '会员管理' },
                        component: resolve => require(['@/views/mis/modules/turnover.vue'], resolve),
                    },
                    {
                        path: '/record',
                        name: 'record',
                        meta: { hindMenu: false, title: '充值退款', parent: 'mis', parentTitle: '会员管理' },
                        component: resolve => require(['@/views/mis/modules/record.vue'], resolve),
                    },
                    {
                        path: '/bill/flow',
                        name: 'bill',
                        meta: { hindMenu: false, title: '订单管理', parent: 'bill', parentTitle: '账单流水' },
                        component: resolve => require(['@/views/bill/modules/flow.vue'], resolve),
                    },
                    {
                        path: '/bill/detail',
                        name: 'detail',
                        meta: { hindMenu: false, title: '交易明细', parent: 'bill', parentTitle: '账单流水' },
                        component: resolve => require(['@/views/bill/modules/detail.vue'], resolve),
                    },
                    {
                        path: '/bill/return',
                        name: 'return',
                        meta: { hindMenu: false, title: '退货单', parent: 'bill', parentTitle: '账单流水' },
                        component: resolve => require(['@/views/bill/modules/return.vue'], resolve),
                    },
                    {
                        path: '/goodsList',
                        name: 'goodsList',
                        meta: { hindMenu: false, title: '商品管理', parent: 'stock', parentTitle: '库存管理' },
                        component: resolve => require(['@/views/stock/modules/goodsList.vue'], resolve),
                    },
                    {
                        path: '/check',
                        name: 'check',
                        meta: { hindMenu: false, title: '盘点', parent: 'stock', parentTitle: '库存管理' },
                        component: resolve => require(['@/views/stock/modules/check.vue'], resolve),
                    },
                    {
                        path: '/damaged',
                        name: 'damaged',
                        meta: { hindMenu: false, title: '报损', parent: 'stock', parentTitle: '库存管理' },
                        component: resolve => require(['@/views/stock/modules/damaged.vue'], resolve),
                    },
                    {
                        path: '/goodsTag',
                        name: 'goodsTag',
                        meta: { hindMenu: false, title: '商品标签', parent: 'stock', parentTitle: '库存管理' },
                        component: resolve => require(['../viewsFastFood/set/goodsTag.vue'], resolve),
                    },
                    {
                        path: '/prepackaged',
                        name: 'prepackaged',
                        meta: { hindMenu: false, title: '预包装标签', parent: 'stock', parentTitle: '库存管理' },
                        component: resolve => require(['../viewsFastFood/set/prepackaged.vue'], resolve),
                    },
                    // {
                    //     path: '/sellOut',
                    //     name: 'sellOut',
                    //     meta: { hindMenu: false, title: '沽清', parent: 'stock', parentTitle: '库存管理' },
                    //     component: resolve => require(['@/views/stock/modules/sellOut.vue'], resolve),
                    // },
                    {
                        path: '/statistics',
                        name: 'statistics',
                        meta: { hindMenu: false, title: '业绩分析', parent: 'reportForm', parentTitle: '统计报表' },
                        component: resolve => require(['@/views/reportForm/modules/statistics.vue'], resolve),
                    },
                    {
                        path: '/orderCount',
                        name: 'orderCount',
                        meta: { hindMenu: false, title: '订单统计', parent: 'reportForm', parentTitle: '统计报表' },
                        component: resolve => require(['@/views/reportForm/modules/orderCount.vue'], resolve),
                    },
                    {
                        path: '/returnGoods',
                        name: 'returnGoods',
                        meta: { hindMenu: true, title: '退货', parent: 'tuihuo', parentTitle: '商品退货' },
                        component: resolve => require(['@/viewsFastFood/returnGoods/returnGoods.vue'], resolve),
                    },
                    {
                        path: '/install/index',
                        name: 'install',
                        meta: { hindMenu: false, title: '打印机设置', parent: 'set', parentTitle: '设置' },
                        component: resolve => require(['@/views/shezhi/modules/index.vue'], resolve),
                    },
                    {
                        path: '/equipment',
                        name: 'equipment',
                        redirect:'/screen',
                        meta: { hindMenu: false, title: '外设设置', parent: 'set', parentTitle: '设置' },
                        component:RouteView,
                        children:[
                            {
                                path: '/screen',
                                name: 'screen',
                                meta: { hindMenu: false, title: '电子屏设置', parent: 'equipment', parentTitle: '外设设置' , Level : 3},
                                component: resolve => require(['@/views/shezhi/modules/screen.vue'], resolve),
                            },
                            {
                                path: '/weight',
                                name: 'weight',
                                meta: { hindMenu: false, title: '通讯秤设置', parent: 'equipment', parentTitle: '外设设置' , Level : 3},
                                component: resolve => require(['@/views/shezhi/modules/weight.vue'], resolve),
                            },
                            // {
                            //     path: '/barcodeScale',
                            //     name: 'barcodeScale',
                            //     meta: { hindMenu: false, title: '条码秤管理', parent: 'equipment', parentTitle: '外设设置' , Level : 3},
                            //     component: resolve => require(['@/viewsFastFood/set/barcode/index.vue'], resolve),
                            // },
                        ]
                    },
                    {
                        path: '/goodsInstall',
                        name: 'goodsInstall',
                        meta: { hindMenu: false, title: '模式设置', parent: 'set', parentTitle: '设置' },
                        component: resolve => require(['../viewsFastFood/set/goodsInstall.vue'], resolve),
                    },
                    {
                        path: '/jiaoban',
                        name: 'jiaoban',
                        meta: { hindMenu: false, title: '交班退出', parent: 'jiaoban', parentTitle: '交班' },
                        component: resolve => require(['../viewsFastFood/jiaoban/jiaoban.vue'], resolve),
                    },
                ]
            },

        ]

    },

    {
        path: '/404',
        component: resolve => require(['../viewsFastFood/exception/404.vue'], resolve),
    },
    {
        path: '/password',
        name: 'Password',
        component: () =>
            import ('@/views/lock/Password.vue')
    },
    {
        path: '*',
        redirect: '/404',
        hidden: true
    }
]

export default fastFood
